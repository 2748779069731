import React, { useState, useEffect } from "react";
import {
  Calendar,
  Modal,
  Select,
  TimePicker,
  Checkbox,
  Button,
  notification,
} from "antd";
import dayjs from "dayjs";
import locale from "antd/lib/date-picker/locale/cs_CZ";
import { useSocket } from "../../SocketProvider";
import "dayjs/locale/cs";
import "./shiftSheduler.scss";

const ULuzickehoSeminare = ({ auth }) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [isModalVisible, setIsModalOpen] = useState(false);
  const [eventStartTime, setEventStartTime] = useState(null);
  const [eventEndTime, setEventEndTime] = useState(null);
  const [isManager, setIsManager] = useState(false);
  const [events, setEvents] = useState({});
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const { RangePicker } = TimePicker;
  const socket = useSocket();

  const isEmployee = auth.userData?.profile?.[
    "urn:zitadel:iam:org:project:284092046917005314:roles"
  ]?.some((role) => role.employee);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      socket.emit("get_employees");
      socket.emit("get_employee_work_hours");

      socket.on("employees_data", (employeesData) => {
        if (isSubscribed) {
          setEmployees(employeesData);
          setActiveEmployees(employeesData.filter((emp) => emp.Active === 1));
        }
      });

      socket.on("employee_work_hours_data", (workHoursData) => {
        if (isSubscribed && employees.length > 0) {
          const transformedEvents = workHoursData.reduce((acc, workHour) => {
            const dateKey = dayjs(workHour.Date).format("YYYY-MM-DD");
            const employee = employees.find(
              (emp) => emp.EmployeeID === workHour.EmployeeID
            );

            const employeeName = employee
              ? `${employee.Name} ${employee.Surname}`
              : `Employee ID ${workHour.EmployeeID}`;
            const event = {
              recordId: workHour.RecordID,
              name: employeeName,
              date: dayjs(workHour.Date),
              startTime: workHour.StartTime.slice(0, 5),
              endTime: workHour.EndTime.slice(0, 5),
              isManager: !!workHour.Manager, // !! prevadi z 0/1 na true/false
              paid: !!workHour.Paid, // !! prevadi z 0/1 na true/false
            };

            (acc[dateKey] = acc[dateKey] || []).push(event);
            return acc;
          }, {});

          setEvents(transformedEvents);
        }
      });
    };

    fetchData();

    socket.on("employee_work_hours_added", () => {
      if (isSubscribed) {
        socket.emit("get_employee_work_hours");
      }
    });
    socket.on("employee_work_hours_updated", () => {
      if (isSubscribed) {
        socket.emit("get_employee_work_hours");
      }
    });
    socket.on("employee_work_hours_deleted", () => {
      if (isSubscribed) {
        socket.emit("get_employee_work_hours");
      }
    });

    return () => {
      isSubscribed = false;
      socket.off("employees_data");
      socket.off("employee_work_hours_data");
      socket.off("employee_work_hours_added");
      socket.off("employee_work_hours_updated");
      socket.off("employee_work_hours_deleted");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, employees.length]);

  const handleOk = () => {
    if (!selectedEmployees || !eventStartTime || !eventEndTime) {
      notification.error({
        message: "Chyba",
        description:
          "Datum, zaměstnanec, čas začátku a čas konce jsou povinné.",
        placement: "bottomRight",
        duration: 2,
      });
      return;
    }

    const modifiedBy = `${auth.userData.profile.given_name} ${auth.userData.profile.family_name}`;
    let eventData;

    if (editingEvent) {
      eventData = {
        recordId: editingEvent.recordId,
        EmployeeID: selectedEmployees,
        startTime: eventStartTime.format("HH:mm:ss"),
        endTime: eventEndTime.format("HH:mm:ss"),
        manager: isManager ? 1 : 0,
        modifiedBy,
      };
      socket.emit("update_employee_work_hours_planner", eventData);
    } else {
      eventData = {
        EmployeeID: selectedEmployees,
        Date: selectedDate.format("YYYY-MM-DD"),
        StartTime: eventStartTime.format("HH:mm:ss"),
        EndTime: eventEndTime.format("HH:mm:ss"),
        Manager: isManager ? 1 : 0,
        Paid: 0,
        modifiedBy,
      };
      socket.emit("add_employee_work_hours_planner", eventData);
    }

    setIsModalOpen(false);
    resetForm();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cellRender = (value) => {
    const dateKey = value.format("YYYY-MM-DD");

    const openAddEventModal = (date) => {
      if (isEmployee) return;
      setSelectedDate(date);
      showModal();
    };

    if (events[dateKey]) {
      const sortedEvents = [...events[dateKey]].sort((a, b) => {
        return a.startTime.localeCompare(b.startTime);
      });
      return (
        <div
          onClick={() => openAddEventModal(value)}
          style={{ cursor: "pointer", height: "100%", padding: "5px" }}
        >
          <ul className="calendar-events">
            {sortedEvents.map((event, index) => (
              <li
                key={index}
                onClick={(clickEvent) => openEventDetails(event, clickEvent)}
              >
                <div
                  className="events"
                  style={{ backgroundColor: getEmployeeColor(event.name) }}
                >
                  <span
                    style={{ fontWeight: event.isManager ? "bold" : "normal" }}
                  >
                    {event.name}
                  </span>
                  {event.startTime} - {event.endTime}
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div
        onClick={() => openAddEventModal(value)}
        style={{ cursor: "pointer", height: "100%", padding: "5px" }}
      />
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
    setSelectedEventDetails(null);
    const now = dayjs();
    setEventStartTime(now.hour(10).minute(0));
    setEventEndTime(now.hour(18).minute(0));
    setIsManager(false);
  };

  const resetForm = () => {
    setEventStartTime(dayjs().hour(10).minute(0));
    setEventEndTime(dayjs().hour(18).minute(0));
    setIsManager(false);
    setSelectedEmployees([]);
    setEditingEvent(null);
  };

  const getEmployeeColor = (name) => {
    switch (name) {
      case "Jan Novák":
        return "#A6E5CE";
      case "Eva Kovářová":
        return "#E9B5B2";
      case "Petr Svoboda":
        return "#A18ED1";
      case "Anna Nováková":
        return "#FFD700";
      case "Marek Svoboda":
        return "#FF7F50";
      default:
        return "#3A393B";
    }
  };

  const getDifferences = (current, previous, employees = []) => {
    const differences = [];
    const fieldsToCheck = [
      "Date",
      "StartTime",
      "EndTime",
      "Manager",
      "EmployeeID",
    ];

    let timeChanged = false;

    fieldsToCheck.forEach((field) => {
      if (current[field] !== previous[field]) {
        if (field === "StartTime" || field === "EndTime") {
          if (!timeChanged) {
            differences.push({
              field: "Time:",
              newValue: `${dayjs(current["StartTime"], "HH:mm:ss").format(
                "HH:mm"
              )} - ${dayjs(current["EndTime"], "HH:mm:ss").format("HH:mm")}`,
              oldValue: `${dayjs(previous["StartTime"], "HH:mm:ss").format(
                "HH:mm"
              )} - ${dayjs(previous["EndTime"], "HH:mm:ss").format("HH:mm")}`,
              modifiedBy: current.ModifiedBy,
              modifiedAt: current.ModifiedAt,
            });
            timeChanged = true;
          }
        } else {
          differences.push({
            field: field === "EmployeeID" ? "Employee:" : field,
            newValue:
              field === "Manager"
                ? current[field]
                  ? "Ano"
                  : "Ne"
                : field === "EmployeeID"
                ? employees.find((emp) => emp.EmployeeID === current[field])
                  ? employees.find((emp) => emp.EmployeeID === current[field])
                      .Name +
                    " " +
                    employees.find((emp) => emp.EmployeeID === current[field])
                      .Surname
                  : `Employee ID ${current[field]}`
                : current[field],
            oldValue:
              field === "Manager"
                ? previous[field]
                  ? "Ano"
                  : "Ne"
                : field === "EmployeeID"
                ? employees.find((emp) => emp.EmployeeID === previous[field])
                  ? employees.find((emp) => emp.EmployeeID === previous[field])
                      .Name +
                    " " +
                    employees.find((emp) => emp.EmployeeID === previous[field])
                      .Surname
                  : `Employee ID ${previous[field]}`
                : previous[field],
            modifiedBy: current.ModifiedBy,
            modifiedAt: current.ModifiedAt,
          });
        }
      }
    });
    return differences;
  };

  // Funkce pro otevření modálu a získání historie úprav
  const openHistoryModal = (recordId) => {
    socket.emit("get_work_hours_history", { recordId });

    socket.on("work_hours_history_data", (historyResults) => {
      console.log(historyResults);
      if (historyResults.length > 0) {
        const differences = [];
        let creationEntry;

        // Find the creation entry and remove it from historyResults
        for (let i = 0; i < historyResults.length; i++) {
          if (historyResults[i].Action === "Vytvořeno") {
            creationEntry = historyResults[i];
            break;
          }
        }

        // Calculate differences for the remaining entries
        for (let i = 1; i < historyResults.length; i++) {
          const current = historyResults[i];
          const previous = historyResults[i - 1];
          differences.push(...getDifferences(current, previous, employees));
        }

        // Add the creation entry as the last item
        if (creationEntry) {
          differences.push({
            field: "Vytvořeno",
            newValue: null,
            oldValue: null,
            modifiedBy: creationEntry.ModifiedBy,
            modifiedAt: creationEntry.ModifiedAt,
          });
        }

        setHistoryData(differences);
        setSelectedEventDetails((prev) => ({
          ...prev,
          history: differences,
        }));
      } else {
        setHistoryData([]);
        setSelectedEventDetails((prev) => ({
          ...prev,
          history: [],
        }));
      }
    });

    socket.on("work_hours_history_error", (error) => {
      notification.error({
        message: "Chyba",
        description: error.message,
        placement: "bottomRight",
        duration: 2,
      });
    });
  };

  const openEventDetails = (event, clickEvent) => {
    clickEvent.stopPropagation();
    setSelectedEventDetails(event);
    setEditingEvent(null);
    openHistoryModal(event.recordId);
  };

  const handleDeleteConfirm = (eventId) => {
    if (isEmployee) {
      notification.error({
        message: "Omezený přístup",
        description: "Nemáte oprávnění mazat směny.",
        placement: "bottomRight",
      });
      return;
    }
    Modal.confirm({
      title: "Opravdu chcete smazat tuto směnu?",
      content: "Tuto akci nelze vrátit zpět.",
      okText: "Ano, smazat",
      okType: "danger",
      cancelText: "Ne, zrušit",
      onOk() {
        deleteEvent(eventId);
      },
    });
  };

  const editEvent = () => {
    const selectedDay = dayjs(selectedEventDetails.date).startOf("day");
    const currentDay = dayjs().startOf("day");
    const loggedInUserName = `${auth.userData.profile.given_name} ${auth.userData.profile.family_name}`;

    if (isEmployee && !selectedDay.isSame(currentDay)) {
      notification.error({
        message: "Omezený přístup",
        description: "Můžete upravovat pouze směny pro aktuální den.",
        placement: "bottomRight",
      });
      return;
    }
    if (selectedEventDetails) {
      if (selectedEventDetails.paid) {
        // notification.error({
        //   message: "Nelze upravit",
        //   description: "Zaplacené směny nelze upravovat.",
        //   placement: "bottomRight",
        // });
        return;
      }

      const employee = employees.find(
        (emp) => `${emp.Name} ${emp.Surname}` === selectedEventDetails.name
      );
      if (isEmployee && loggedInUserName !== selectedEventDetails.name) {
        notification.error({
          message: "Omezený přístup",
          description: "Můžete upravovat pouze své vlastní směny.",
          placement: "bottomRight",
        });
        return;
      }

      // Předpokládáme, že máte k dispozici EmployeeID
      setEventStartTime(dayjs(selectedEventDetails.startTime, "HH:mm"));
      setEventEndTime(dayjs(selectedEventDetails.endTime, "HH:mm"));
      setIsManager(selectedEventDetails.isManager);
      // Nastavení EmployeeID pro Select komponentu
      setSelectedEmployees(employee ? [employee.EmployeeID] : []);
      setIsModalOpen(true);
      setEditingEvent(selectedEventDetails);
      setSelectedEventDetails(null);
    }
  };

  const deleteEvent = (recordId) => {
    const modifiedBy = `${auth.userData.profile.given_name} ${auth.userData.profile.family_name}`;

    if (recordId) {
      socket.emit("delete_employee_work_hours", { recordId, modifiedBy });
      setSelectedEventDetails(null);
    }
  };

  return (
    <div className={`ShiftContainer `}>
      <Calendar locale={locale} cellRender={cellRender} />
      <Modal
        title={editingEvent ? "Upravit docházku" : "Přidat docházku"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          placeholder="Vyber zaměstnance"
          className="shift-employee-select"
          value={selectedEmployees}
          onChange={(value) => setSelectedEmployees(value)}
          style={{ width: "100%" }}
          disabled={isEmployee || isManager}
        >
          {activeEmployees.map((employee) => (
            <Select.Option
              key={employee.EmployeeID}
              value={employee.EmployeeID}
            >
              {employee.Name} {employee.Surname}
            </Select.Option>
          ))}
        </Select>

        <div>
          <RangePicker
            className="calendar-event-time-picker"
            locale={locale}
            value={[eventStartTime, eventEndTime].map((time) =>
              time ? dayjs(time) : null
            )}
            format={"HH:mm"}
            onChange={(times) => {
              const [startTime, endTime] = times;
              setEventStartTime(startTime ? dayjs(startTime) : null);
              setEventEndTime(endTime ? dayjs(endTime) : null);
            }}
          />
        </div>
        <div>
          <Checkbox
            checked={isManager}
            onChange={(e) => setIsManager(e.target.checked)}
            disabled={isEmployee}
          >
            Vedoucí
          </Checkbox>
        </div>
      </Modal>

      <Modal
        title="Detail"
        open={selectedEventDetails}
        onCancel={() => setSelectedEventDetails(null)}
        footer={[
          <Button
            key="delete"
            type="danger"
            onClick={() => handleDeleteConfirm(selectedEventDetails?.recordId)}
          >
            Smazat
          </Button>,
          <Button
            key="edit"
            type="primary"
            disabled={selectedEventDetails?.paid}
            onClick={editEvent}
          >
            Upravit
          </Button>,
        ]}
      >
        {selectedEventDetails && (
          <div>
            <div className="itemDetail">
              <div className="left-column">
                <p>
                  {selectedEventDetails.startTime} -{" "}
                  {selectedEventDetails.endTime}
                </p>
              </div>
              <div className="right-column">
                <p>
                  {selectedEventDetails.name} -{" "}
                  {selectedEventDetails.isManager ? "Vedoucí" : "Zaměstnanec"}
                </p>
              </div>
            </div>
            {/*    {!isEmployee && (
              <>
                <h3>Historie úprav</h3>
                <ul>
                  {selectedEventDetails.history &&
                    selectedEventDetails.history.map((record, index) => (
                      <li key={index} className="history-item">
                        <div className="left-column">
                          {dayjs(record.modifiedAt).format("DD.MM.YYYY HH:mm")}{" "}
                          - {record.modifiedBy}
                        </div>
                        <div className="right-column">
                          {record.field} <strong>{record.newValue}</strong>{" "}
                          {record.oldValue && `->`}{" "}
                          <strong>{record.oldValue}</strong>
                        </div>
                      </li>
                    ))}
                </ul>
              </>
            )} */}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ULuzickehoSeminare;
