import React, { useState, useEffect } from "react";
import {
  Form,
  InputNumber,
  Switch,
  Button,
  Table,
  notification,
  Input,
  Popconfirm,
  Space,
  Select,
  Modal,
} from "antd";

import dayjs from "dayjs";
import { useAuth } from "oidc-react";
import { useSocket } from "../../SocketProvider";
import "./inventoryForm.scss";

const InventoryForm = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editStates, setEditStates] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState({});
  const [form] = Form.useForm();

  const socket = useSocket();
  const auth = useAuth();

  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.[
    "urn:zitadel:iam:org:project:284092046917005314:roles"
  ]?.some((role) => role.employee);

  const loadInventoryData = () => {
    socket.emit("get_stock_inventory");
  };

  useEffect(() => {
    loadInventoryData();

    socket.on("stock_inventory_data", (data) => {
      const transformedData = data.map((item, index) => ({
        key: index.toString(),
        id: item.id,
        name: item.name,
        category: item.category,
        size: item.size || "N/A",
        quantity: item.quantity,
        minQuantity: item.minQuantity,
        maxQuantity: item.maxQuantity,
        orderQuantity: item.orderQuantity,
        Expiration: item.Expiration ? dayjs(item.Expiration) : null,
        onWay: item.onWay === 1,
        active: item.active === 1,
        last_update: dayjs(item.last_update).format("YYYY-MM-DD HH:mm:ss"),
        last_update_by: item.last_update_by || "N/A",
      }));
      setInventoryData(transformedData);
      setFilteredData(transformedData);

      const uniqueCategories = Array.from(
        new Set(data.map((item) => item.category))
      ).map((category) => ({
        text: category,
        value: category,
      }));
      setCategories(uniqueCategories);
    });

    socket.on("update_stock_inventory_success", (message) => {
      notification.success({
        message: "Úspěch",
        description: "Záznam byl úspěšně aktualizován.",
        placement: "bottomRight",
      });
      loadInventoryData();
    });
    socket.on("update_inventory_details_success", (message) => {
      notification.success({
        message: "Úspěch",
        description: "Záznam byl úspěšně aktualizován.",
        placement: "bottomRight",
      });
      loadInventoryData();
    });

    socket.on("add_stock_inventory_success", (message) => {
      notification.success({
        message: "Úspěch",
        description: "Záznam byl úspěšně přidán.",
        placement: "bottomRight",
      });
      loadInventoryData();
    });

    // Error
    socket.on("update_stock_inventory_error", (data) => {
      notification.error({
        message: "Chyba",
        description: data.message || "Došlo k chybě při aktualizaci záznamu.",
        placement: "bottomRight",
      });
    });
    socket.on("update_inventory_details_error", (data) => {
      notification.error({
        message: "Chyba",
        description: data.message || "Došlo k chybě při aktualizaci záznamu.",
        placement: "bottomRight",
      });
    });
    socket.on("add_stock_inventory_error", (data) => {
      notification.error({
        message: "Chyba",
        description: data.message || "Nepodařilo se přidat nový řádek",
        placement: "bottomRight",
      });
    });
    return () => {
      socket.off("stock_inventory_data");
      socket.off("stock_inventory_error");
      socket.off("update_stock_inventory_success");
      socket.off("add_stock_inventory_success");
      socket.off("update_inventory_details_success");
      socket.off("update_stock_inventory_error");
      socket.off("update_inventory_details_error");
      socket.off("add_stock_inventory_error");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
    const filteredData = inventoryData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const handleEditChange = (key, value, recordKey) => {
    setEditStates((prevState) => ({
      ...prevState,
      [recordKey]: {
        ...(prevState[recordKey] || {}),
        [key]: value,
      },
    }));
  };

  const handleSave = () => {
    const last_update_by = auth.userData?.profile
      ? `${auth.userData.profile.given_name} ${auth.userData.profile.family_name}`
      : "undefined user";

    const updates = Object.entries(editStates)
      .map(([key, values]) => {
        const record = inventoryData.find((record) => record.key === key);
        if (!record) return null; // Pokud nenajdeme záznam, vrátíme null a později odfiltrujeme

        return {
          id: record.id,
          quantity: values.quantity ?? record.quantity,
          orderQuantity: values.orderQuantity ?? record.orderQuantity,
          expiration:
            values.Expiration ?? record.Expiration
              ? dayjs(values.Expiration).format("YYYY-MM-DD")
              : null,
          onWay: values.onWay ?? record.onWay ? 1 : 0,
          active: values.active ?? record.active ? 1 : 0,
          last_update_by: last_update_by,
        };
      })
      .filter((update) => update !== null); // Odfiltrujeme všechny null hodnoty

    // Odeslání pole aktualizací na server pomocí WebSocketu
    socket.emit("update_stock_inventory", updates);
  };

  const handleAddNewRow = () => {
    const last_update_by = auth.userData?.profile
      ? `${auth.userData.profile.given_name} ${auth.userData.profile.family_name}`
      : "undefined user";
    // Sestavení dat nového řádku
    const newRow = {
      name: "Nový produkt",
      category: "Kategorie",
      size: "Velikost",
      quantity: 0,
      orderQuantity: 0,
      Expiration: "2023-12-31",
      onWay: 0,
      active: 1,
      last_update_by: last_update_by,
    };

    // Odeslání dat nového řádku na server
    socket.emit("add_stock_inventory", newRow);
  };

  const handleDelete = (key) => {
    // Remove the item from the state
    const newData = inventoryData.filter((item) => item.key !== key);
    setInventoryData(newData);
    setFilteredData(newData);

    // Optionally, emit an event to your backend/server to delete the item
    const itemToDelete = inventoryData.find((item) => item.key === key);
    if (itemToDelete) {
      socket.emit("delete_stock_inventory", { id: itemToDelete.id });
    }

    // Notify the user
    notification.success({
      message: "Deleted",
      description: "The item has been successfully deleted.",
      placement: "bottomRight",
    });
  };

  const handleOk = () => {
    socket.emit("update_inventory_details", {
      id: editingRecord.id,
      name: editingRecord.name,
      category: editingRecord.category,
      size: editingRecord.size,
      minQuantity: editingRecord.minQuantity,
    });

    setIsEditing(false);
  };

  const handleEdit = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const getRowClassName = (record) => {
    if (record.quantity === 0) {
      if (record.onWay) {
        return "row-warning-on-way";
      }
      return "row-danger";
    }
    if (record.quantity <= record.minQuantity) {
      if (record.onWay) {
        return "row-warning-on-way";
      }
      return "row-warning";
    }
    return "";
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const addButton = !isEmployee ? (
    <Button
      type="primary"
      onClick={handleAddNewRow}
      style={{ marginBottom: 16 }}
    >
      Přidat Produkt
    </Button>
  ) : null;

  const getColumns = () => {
    let baseColumns = [
      {
        title: "Název",
        dataIndex: "name",
        key: "name",
        width: 100,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: "Kategorie",
        dataIndex: "category",
        key: "category",
        width: 100,
        filters: categories,
        onFilter: (value, record) => record.category === value,
        sorter: (a, b) => a.category.localeCompare(b.category),
      },
      {
        title: "Velikost",
        dataIndex: "size",
        key: "size",
        width: 100,
      },
      {
        title: "Množství",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        sorter: (a, b) => a.quantity - b.quantity,
        render: (text, record) => (
          <InputNumber
            min={0}
            value={editStates[record.key]?.quantity ?? text}
            onChange={(value) =>
              handleEditChange("quantity", value, record.key)
            }
          />
        ),
      },
      ...(!isEmployee
        ? [
            {
              title: "Minimální množství",
              dataIndex: "minQuantity",
              key: "minQuantity",
              width: 100,
              sorter: (a, b) => a.minQuantity - b.minQuantity,
            },
          ]
        : []),
      {
        title: "Objednat",
        dataIndex: "orderQuantity",
        key: "orderQuantity",
        width: 100,
        sorter: (a, b) => a.orderQuantity - b.orderQuantity,
        render: (text, record) => (
          <InputNumber
            min={0}
            value={editStates[record.key]?.orderQuantity ?? text}
            onChange={(value) =>
              handleEditChange("orderQuantity", value, record.key)
            }
          />
        ),
      },
      {
        title: "Objednáno",
        dataIndex: "onWay",
        key: "onWay",
        width: 100,
        sorter: (a, b) => a.onWay - b.onWay,
        render: (text, record) => {
          if (isEmployee) {
            return text ? "Ano" : "Ne";
          }

          return (
            <Switch
              checked={editStates[record.key]?.onWay ?? text}
              onChange={(checked) =>
                handleEditChange("onWay", checked, record.key)
              }
            />
          );
        },
      },
      ...(!isEmployee
        ? [
            {
              title: "Aktivní",
              dataIndex: "active",
              key: "active",
              defaultFilteredValue: ["true"],
              filters: [
                { text: "Aktivní", value: true },
                { text: "Neaktivní", value: false },
              ],
              width: 80,
              onFilter: (value, record) => record.active === value,
              render: (text, record) => (
                <Switch
                  checked={editStates[record.key]?.active ?? text}
                  onChange={(checked) =>
                    handleEditChange("active", checked, record.key)
                  }
                />
              ),
            },
          ]
        : []),
      {
        title: "Upravil",
        dataIndex: "last_update_by",
        key: "last_update_by",
        width: 100,
      },
      {
        title: "Poslední aktualizace",
        dataIndex: "last_update",
        key: "last_update",
        width: 150,
      },
    ];
    // Edit & Delete
    if (!isEmployee) {
      baseColumns.push({
        title: "",
        key: "action",
        width: 80,
        render: (_, record) => (
          <Space size="middle">
            <Button onClick={() => handleEdit(record)}>Upravit</Button>
            <Popconfirm
              title="Opravdu chcete smazat tento řádek?"
              onConfirm={() => handleDelete(record.key)}
              okText="Ano"
              cancelText="Ne"
            >
              <Button type="danger">Smazat</Button>
            </Popconfirm>
          </Space>
        ),
      });
    }
    return baseColumns;
  };
  const columns = getColumns();

  return (
    <div>
      <Input
        placeholder="Vyhledat podle názvu"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 16, width: "200px" }}
      />

      {addButton}
      <Form form={form} layout="vertical">
        <Table
          dataSource={filteredData}
          columns={columns}
          pagination={false}
          rowClassName={getRowClassName}
        />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSave}
            className="saveButtonFixed"
          >
            Uložit
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="default"
            className="toTopButton"
            onClick={handleScrollToTop}
          >
            Top
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="Upravit záznam"
        open={isEditing}
        onOk={handleOk}
        onCancel={() => setIsEditing(false)}
      >
        <Form layout="vertical">
          <Form.Item label="Název">
            <Input
              value={editingRecord.name}
              onChange={(e) =>
                setEditingRecord((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Form.Item>
          <Form.Item label="Kategorie">
            <Select
              value={editingRecord.category}
              onChange={(value) =>
                setEditingRecord((prev) => ({ ...prev, category: value }))
              }
            >
              {categories.map((cat) => (
                <Select.Option key={cat.value} value={cat.value}>
                  {cat.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Velikost">
            <Input
              value={editingRecord.size}
              onChange={(e) =>
                setEditingRecord((prev) => ({ ...prev, size: e.target.value }))
              }
            />
          </Form.Item>
          <Form.Item label="Minimální množství">
            <Input
              value={editingRecord.minQuantity}
              onChange={(e) =>
                setEditingRecord((prev) => ({
                  ...prev,
                  minQuantity: e.target.value,
                }))
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default InventoryForm;
