import React, { useState, useEffect } from "react";
import {
  Modal,
  List,
  message,
  Button,
  Input,
  Tooltip,
  Menu,
  Dropdown,
  Spin,
  Breadcrumb,
} from "antd";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import callApi from "../../ApiCaller";
import "./ImageUpload.scss";

registerPlugin(FilePondPluginImagePreview);

const ImageUpload = ({ identifier, identifierType, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [file, setFile] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentDir, setCurrentDir] = useState("");
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (visible) {
      fetchImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, currentDir]);

  const fetchImages = async () => {
    setIsLoading(true);
    try {
      const response = await callApi(`images?dir=${currentDir}`);
      if (!response.error) {
        const uniqueImages = getUniqueImages(response);
        setImages(uniqueImages);
      }
    } catch (error) {
      console.error("Chyba při načítání obrázků:", error);
      message.error("Nepodařilo se načíst obrázky.");
    } finally {
      setIsLoading(false);
    }
  };

  const getUniqueImages = (images) => {
    const seenUrls = new Set();
    return images.filter((image) => {
      if (image.isDirectory || !seenUrls.has(image.url)) {
        seenUrls.add(image.url);
        return true;
      }
      return false;
    });
  };

  const handleSelect = async (image) => {
    setIsLoading(true);
    if (image.isDirectory) {
      setCurrentDir(image.path);
    } else {
      try {
        const response = await callApi(`update-image/${identifier}`, "PUT", {
          ImageURL: image.url.replace("https://api.tomasdinh.cz/public/", ""),
          ThumbnailURL: `thumbnails/${image.url.split("/").pop()}`,
          type: identifierType,
        });
        if (response.error) {
          message.error("Chyba při aktualizaci obrázku.");
        } else {
          message.success("Obrázek byl úspěšně aktualizován.");
          onUpdate(image.name);
        }
      } catch (error) {
        message.error("Chyba při aktualizaci obrázku.");
      } finally {
        setIsLoading(false);
      }
      setVisible(false);
    }
  };

  const handleBack = () => {
    const parentDir = currentDir.split("/").slice(0, -1).join("/");
    setCurrentDir(parentDir);
  };

  const handleDelete = async (item) => {
    console.log(item);
  };

  const handleRename = async (item, newName) => {
    try {
      if (!item.type || !item.ImageID) {
        throw new Error("Invalid item type or ID");
      }

      const response = await callApi(`rename-image/${item.ImageID}`, "PUT", {
        newName,
        type: item.type,
      });

      if (response.error) {
        message.error("Chyba při přejmenování.");
      } else {
        message.success("Přejmenování bylo úspěšné.");
        fetchImages();
      }
    } catch (error) {
      message.error("Chyba při přejmenování.");
      console.error("Rename error:", error);
    }
  };

  const menu = (item) => {
    const menuItems = [
      {
        key: "rename",
        label: "Přejmenovat",
        onClick: () => handleRenameClick(item),
      },
      {
        key: "delete",
        label: "Odstranit",
        onClick: () => handleDelete(item),
      },
    ];

    return <Menu items={menuItems} />;
  };

  const filteredImages = images.filter((image) =>
    image.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRenameClick = (item) => {
    setSelectedItem(item);
    setRenameModalVisible(true);
    setTimeout(() => {
      document.getElementById("newName").value = item.name;
    }, 0);
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const validateFileName = (file) => {
    // eslint-disable-next-line no-control-regex, no-useless-escape
    const invalidChars = /[<>:"\/\\|?*\x00-\x1F]/g;
    if (invalidChars.test(file.name)) {
      message.error("Název souboru obsahuje nepovolené znaky.");
      return false;
    }
    return true;
  };

  const renderBreadcrumb = () => {
    const paths = currentDir.split("/").filter((path) => path);
    return (
      <Breadcrumb>
        {paths.map((path, index) => (
          <Breadcrumb.Item key={index}>{path}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  return (
    <>
      <Button onClick={handleOpen}>Vybrat nebo nahrát obrázek</Button>
      <Modal
        open={visible}
        title="Vybrat nebo nahrát obrázek"
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <FilePond
          files={file}
          onupdatefiles={setFile}
          allowMultiple={false}
          maxFiles={1}
          beforeAddFile={(file) => {
            if (!validateFileName(file.file)) {
              return false;
            }
            return true;
          }}
          server={{
            process: (
              fieldName,
              file,
              metadata,
              load,
              error,
              progress,
              abort
            ) => {
              const formData = new FormData();
              formData.append("image", file);
              formData.append("directory", currentDir);
              formData.append(identifierType, identifier);

              const request = new XMLHttpRequest();

              request.open("POST", "https://api.tomasdinh.cz/api/upload");

              // Set the x-api-key header
              request.setRequestHeader(
                "x-api-key",
                process.env.REACT_APP_ADMIN_API_KEY
              );

              request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
              };

              request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                  const response = JSON.parse(request.responseText);
                  if (response.message === "File already exists") {
                    message.error(response.message);
                  } else {
                    const uniqueImages = getUniqueImages(
                      images.map((img) =>
                        img[identifierType] === identifier ? response.data : img
                      )
                    );
                    setImages(uniqueImages);
                    onUpdate(response.data.ImageID);
                    load(response.data.ImageID);
                  }
                } else {
                  error("Chyba při nahrávání obrázku.");
                }
              };

              request.send(formData);

              return {
                abort: () => {
                  request.abort();
                  abort();
                },
              };
            },
          }}
          name="image"
          labelIdle='Přetáhněte obrázek nebo <span class="filepond--label-action">Procházet</span>'
        />
        <Spin spinning={isLoading}>
          <Input
            placeholder="Hledat obrázek"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: 16 }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            {currentDir && (
              <Button
                onClick={handleBack}
                style={{ marginRight: "auto", marginBottom: 16 }}
              >
                Zpět
              </Button>
            )}
            {renderBreadcrumb()}
            <Button
              onClick={() => setVisible(false)}
              style={{ marginLeft: "auto" }}
            >
              Uložit
            </Button>
          </div>

          <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={filteredImages}
            renderItem={(item) =>
              item.isDirectory ? (
                <List.Item
                  onClick={() => handleSelect(item)}
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <div className="image-container" style={directoryStyle}>
                    <div style={{ fontSize: 48 }}>📁</div>
                    <div>{item.name}</div>
                  </div>
                </List.Item>
              ) : (
                <Dropdown overlay={menu(item)} trigger={["contextMenu"]}>
                  <List.Item onClick={() => handleSelect(item)}>
                    <Tooltip title={item.name}>
                      <div
                        className="image-container"
                        style={imageContainerStyle}
                      >
                        <img
                          src={item.url}
                          alt={item.name}
                          style={imageStyle}
                        />
                      </div>
                    </Tooltip>
                  </List.Item>
                </Dropdown>
              )
            }
          />
        </Spin>
      </Modal>
      <Modal
        title="Přejmenovat obrázek"
        open={renameModalVisible}
        onOk={() => {
          setRenameModalVisible(false);
        }}
        onCancel={() => setRenameModalVisible(false)}
      >
        <Input
          id="newName"
          defaultValue={selectedItem ? selectedItem.name : ""}
          key={selectedItem ? selectedItem.ImageID : "default"}
        />
      </Modal>
    </>
  );
};

const directoryStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  cursor: "pointer",
  width: "100%",
  height: "150px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out",
  borderRadius: "4px",
  textAlign: "center",
  padding: "16px",
  backgroundColor: "#f9f9f9",
};

const imageContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out",
  borderRadius: "4px",
  overflow: "hidden",
  width: "100%",
  height: "150px",
};

const imageStyle = {
  cursor: "pointer",
  display: "block",
  maxHeight: "140px",
};

export default ImageUpload;
