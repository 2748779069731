import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Card,
  message,
  Modal,
  Row,
  Col,
  Popconfirm,
  Input,
  Image,
} from "antd";
import { useAuth } from "oidc-react";
import callApi from "../../ApiCaller";
import AddIntermediateProductRecipe from "./AddIntermediateProductRecipe";
import EditIntermediateProductRecipe from "./EditIntermediateProductRecipe";

const IntermediateProductRecipes = () => {
  const [recipes, setRecipes] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [intermediateProducts, setIntermediateProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const url = "https://api.tomasdinh.cz/public/";
  const auth = useAuth();

  const isEmployee = auth.userData?.profile?.[
    "urn:zitadel:iam:org:project:284092046917005314:roles"
  ]?.some((role) => role.employee);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [recipesResponse, suppliesResponse, productsResponse] =
        await Promise.all([
          callApi("intermediate-products/recipes"),
          callApi("supplies"),
          callApi("intermediate-products"),
        ]);

      if (recipesResponse.error) {
        setError(recipesResponse.message);
      } else {
        const recipesWithProductDetails = recipesResponse.map((recipe) => {
          const product = productsResponse.find(
            (product) =>
              product.IntermediateProductID === recipe.IntermediateProductID
          );
          return {
            ...recipe,
            IntermediateProduct: product,
          };
        });
        setRecipes(recipesWithProductDetails);
      }

      if (suppliesResponse.error) {
        setError(suppliesResponse.message);
      } else {
        setSupplies(suppliesResponse);
      }

      if (productsResponse.error) {
        setError(productsResponse.message);
      } else {
        const productsWithImages = productsResponse.map((product) => {
          return {
            ...product,
            ImageURL: product.ProductImages[0]?.ImageURL
              ? `https://api.tomasdinh.cz/public/${product.ProductImages[0].ImageURL}`
              : null,
          };
        });
        setIntermediateProducts(productsWithImages);
      }
    } catch (error) {
      setError(error.message);
      message.error("Chyba při načítání dat");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (record) => {
    setSelectedRecipe(record);
    setDetailModalVisible(true);
  };

  const handleAddNewRecipe = (newRecipe) => {
    setAddModalVisible(false);
    fetchData();
  };

  const handleUpdateRecipe = (updatedRecipe) => {
    setEditModalVisible(false);
    fetchData();
  };

  const handleDeleteRecipe = async (recipeId) => {
    try {
      // Zavolej nový endpoint pro kontrolu
      const checkResponse = await callApi(
        `intermediate-products/check/${recipeId}`,
        "GET"
      );

      if (checkResponse.error || checkResponse.status >= 400) {
        message.error(
          checkResponse.message ||
            "Produkt je používán v receptu, nejdříve ho odstraňte z receptu."
        );
        return;
      }

      // Pokud produkt není používán, pokračuj s mazáním
      const deleteResponse = await callApi(
        `intermediate-products/${recipeId}`,
        "DELETE"
      );

      if (deleteResponse.error || deleteResponse.status >= 400) {
        message.error(
          deleteResponse.message || "Chyba při odstraňování produktu"
        );
      } else {
        setRecipes(
          recipes.filter(
            (recipe) => recipe.IntermediateProductRecipeID !== recipeId
          )
        );
        message.success("Produkt úspěšně odstraněn");
        setDetailModalVisible(false);
        fetchData();
      }
    } catch (error) {
      message.error(error.message || "Chyba při odstraňování produktu");
    }
  };

  const handleFilterCategory = (category) => {
    setFilteredCategory(category);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRecipes = recipes.filter((recipe) => {
    const matchesCategory =
      filteredCategory === null ||
      recipe.IntermediateProduct?.Category === filteredCategory;
    const matchesSearchTerm =
      recipe.IntermediateProduct?.Name.toLowerCase().includes(
        searchTerm.toLowerCase()
      );
    return matchesCategory && matchesSearchTerm;
  });

  const columns = [
    {
      title: "",
      dataIndex: ["IntermediateProduct", "ProductImages"],
      key: "IntermediateProduct.ProductImages",
      width: 50,
      render: (images) =>
        images && images[0]?.ThumbnailURL ? (
          <img
            width={50}
            src={`${url}${images[0].ThumbnailURL}`}
            alt="Thumbnail"
          />
        ) : (
          "No Image"
        ),
    },
    {
      title: "Název",
      dataIndex: ["IntermediateProduct", "Name"],
      key: "IntermediateProduct.Name",
      width: 200,
      sorter: (a, b) =>
        a.IntermediateProduct?.Name?.localeCompare(b.IntermediateProduct?.Name),
    },
    {
      title: "Kategorie",
      dataIndex: ["IntermediateProduct", "Category"],
      key: "IntermediateProduct.Category",
      width: 200,
      sorter: (a, b) =>
        a.IntermediateProduct?.Category?.localeCompare(
          b.IntermediateProduct?.Category
        ),
      filters: [
        ...new Set(
          recipes.map((recipe) => recipe.IntermediateProduct?.Category)
        ),
      ]
        .filter((category) => category)
        .map((category) => ({ text: category, value: category })),
      onFilter: (value, record) =>
        record.IntermediateProduct?.Category === value,
    },
    {
      title: "Měrná jednotka",
      dataIndex: ["IntermediateProduct", "Unit"],
      key: "IntermediateProduct.Unit",
      width: 200,
      sorter: (a, b) =>
        a.IntermediateProduct?.Unit?.localeCompare(b.IntermediateProduct?.Unit),
      filters: [
        ...new Set(recipes.map((recipe) => recipe.IntermediateProduct?.Unit)),
      ]
        .filter((unit) => unit)
        .map((unit) => ({ text: unit, value: unit })),
      onFilter: (value, record) => record.IntermediateProduct?.Unit === value,
    },
    {
      title: "Typ",
      dataIndex: ["IntermediateProduct", "Type"],
      key: "IntermediateProduct.Type",
      width: 200,
      sorter: (a, b) =>
        a.IntermediateProduct?.Type?.localeCompare(b.IntermediateProduct?.Type),
      filters: [
        ...new Set(recipes.map((recipe) => recipe.IntermediateProduct?.Type)),
      ]
        .filter((type) => type)
        .map((type) => ({ text: type, value: type })),
      onFilter: (value, record) => record.IntermediateProduct?.Type === value,
    },
  ];

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 15,
          marginTop: 5,
        }}
      >
        <div>
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => setAddModalVisible(true)}
            disabled={isEmployee}
          >
            Přidat nový produkt
          </Button>
          {[
            ...new Set(
              recipes.map((recipe) => recipe.IntermediateProduct?.Category)
            ),
          ]
            .sort()
            .map((category) => (
              <Button
                key={category}
                onClick={() => handleFilterCategory(category)}
                style={{
                  marginRight: 8,
                  backgroundColor:
                    filteredCategory === category ? "#1890ff" : "",
                  color: filteredCategory === category ? "#fff" : "",
                }}
              >
                {category}
              </Button>
            ))}
          <Button
            onClick={() => handleFilterCategory(null)}
            style={{
              backgroundColor: filteredCategory === null ? "#1890ff" : "",
              color: filteredCategory === null ? "#fff" : "",
            }}
          >
            Vše
          </Button>
        </div>
        <Input.Search
          placeholder="Vyhledávání..."
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: 185 }}
        />
      </div>

      <Table
        columns={columns}
        dataSource={filteredRecipes}
        rowKey="IntermediateProductRecipeID"
        loading={loading}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          onMouseEnter: (e) => {
            e.currentTarget.style.fontWeight = "bold";
            e.currentTarget.style.cursor = "pointer";
          },
          onMouseLeave: (e) => {
            e.currentTarget.style.fontWeight = "normal";
            e.currentTarget.style.cursor = "default";
          },
        })}
      />
      <AddIntermediateProductRecipe
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onAdd={handleAddNewRecipe}
      />
      <Modal
        title={selectedRecipe?.IntermediateProduct?.Name}
        open={detailModalVisible}
        onCancel={() => setDetailModalVisible(false)}
        footer={null}
        width={800}
      >
        <Row gutter={16}>
          <Col
            span={8}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedRecipe?.IntermediateProduct?.ProductImages[0]?.ImageURL ? (
              <Image
                width={240}
                src={`${url}${selectedRecipe.IntermediateProduct?.ProductImages[0]?.ImageURL}`}
                alt="Intermediate Product"
              />
            ) : (
              <p>Žádný obrázek není k dispozici</p>
            )}
          </Col>
          <Col span={16}>
            <div style={{ minHeight: "550px" }}>
              <div style={{ paddingBottom: "30px" }}>
                <h5>Popis</h5>
                <p>{selectedRecipe?.Description}</p>
                <br />
                <h5>Ingredience:</h5>
                <ul style={{ paddingLeft: 20 }}>
                  {selectedRecipe?.IntermediateProductRecipeIngredients?.map(
                    (ingredient) => {
                      const supply = supplies.find(
                        (supply) => supply.SupplyID === ingredient.SupplyID
                      );
                      return (
                        <li key={ingredient.SupplyID}>
                          {supply?.Name}: {ingredient.Quantity} {supply?.Unit1}
                        </li>
                      );
                    }
                  )}
                </ul>
                <br />
                <h5>Postup:</h5>
                <p>{selectedRecipe?.Preparation}</p>
                <br />
                <h5>Poznámky</h5>
                <p>{selectedRecipe?.Note}</p>
              </div>
              <Button
                onClick={() => {
                  setEditModalVisible(true);
                  setDetailModalVisible(false);
                }}
                style={{ position: "absolute", bottom: 0 }}
                disabled={isEmployee}
              >
                Upravit
              </Button>
              <Popconfirm
                title="Jste si jisti, že chcete odstranit tento produkt?"
                onConfirm={() =>
                  handleDeleteRecipe(selectedRecipe.IntermediateProductID)
                }
                okText="Ano"
                cancelText="Ne"
              >
                <Button
                  type="text"
                  danger
                  style={{ position: "absolute", bottom: 0, right: 10 }}
                  disabled={isEmployee}
                >
                  Odstranit produkt
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Modal>

      {selectedRecipe && (
        <EditIntermediateProductRecipe
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          onUpdate={handleUpdateRecipe}
          onDelete={handleDeleteRecipe}
          recipe={selectedRecipe}
          supplies={supplies}
          intermediateProducts={intermediateProducts}
        />
      )}
    </Card>
  );
};

export default IntermediateProductRecipes;
