import React, { useEffect, useState } from "react";
import callApi from "../../ApiCaller";
import {
  Table,
  Modal,
  Tabs,
  Spin,
  Alert,
  Button,
  Row,
  Col,
  message,
  Input,
  Popconfirm,
  Card,
  Image,
} from "antd";
import AddNewProductModal from "./AddNewProductModal";
import EditRecipeModal from "./EditRecipeModal";
import IntermediateProductRecipes from "./IntermediateProductRecipes";
import Ingredients from "./Ingredients";
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { useAuth } from "oidc-react";

import "./Recipe.scss";

function Recipe() {
  const url = "https://api.tomasdinh.cz/public/";
  const [products, setProducts] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [newProductModalVisible, setNewProductModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [addingVariation, setAddingVariation] = useState(false);
  const [newVariationName, setNewVariationName] = useState("");
  const [addingSize, setAddingSize] = useState(false);
  const [newSizeName, setNewSizeName] = useState("");
  const [activeTabKey, setActiveTabKey] = useState(null);
  const [activeSizeKey, setActiveSizeKey] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const auth = useAuth();

  const isEmployee = auth.userData?.profile?.[
    "urn:zitadel:iam:org:project:284092046917005314:roles"
  ]?.some((role) => role.employee);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsResponse, suppliesResponse] = await Promise.all([
          callApi("recipes"),
          callApi("supplies"),
        ]);

        if (productsResponse.error) {
          setError(productsResponse.message);
        } else {
          setProducts(productsResponse);
        }

        if (suppliesResponse.error) {
          setError(suppliesResponse.message);
        } else {
          setSupplies(suppliesResponse);
        }
      } catch (error) {
        setError(error.message);
        message.error("Chyba při načítání dat");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchProductById = async (productId) => {
    try {
      const updatedProduct = await callApi(`products/${productId}`);
      if (updatedProduct.error) {
        message.error(updatedProduct.message);
      } else {
        setSelectedProduct(updatedProduct);
        const sortedVariations = updatedProduct.ProductVariations.sort((a, b) =>
          a.Variation.localeCompare(b.Variation)
        );
        const firstVariation = sortedVariations[0];
        setActiveTabKey(firstVariation?.VariationID || null);

        const sortedSizes = firstVariation.ProductSizes.sort((a, b) =>
          a.Size.localeCompare(b.Size)
        );
        const firstSize = sortedSizes[0];
        setActiveSizeKey(firstSize?.SizeID || null);
        setSelectedImage(firstSize?.ProductImages?.[0]?.ImageURL || null);
      }
    } catch (error) {
      message.error("Chyba při načítání dat produktu");
    }
  };

  const handleRowClick = async (record) => {
    await fetchProductById(record.ProductID);
    setModalVisible(true);
  };

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const handleAddNewProduct = async () => {
    try {
      const productsResponse = await callApi("recipes");
      if (productsResponse.error) {
        setError(productsResponse.message);
      } else {
        setProducts(productsResponse);
      }
      setNewProductModalVisible(false);
    } catch (error) {
      setError(error.message);
      message.error("Chyba při načítání dat");
    }
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
    const selectedVariation = selectedProduct.ProductVariations.find(
      (variation) => variation.VariationID === key
    );
    if (selectedVariation) {
      const firstSize = selectedVariation.ProductSizes.sort((a, b) =>
        a.Size.localeCompare(b.Size)
      )[0];
      setActiveSizeKey(firstSize?.SizeID || null);
      setSelectedImage(firstSize?.ProductImages?.[0]?.ImageURL || null);
    } else {
      setActiveSizeKey(null);
      setSelectedImage(null);
    }
  };

  const handleSizeTabChange = (key) => {
    setActiveSizeKey(key);
    const selectedSize = selectedProduct.ProductVariations.flatMap(
      (variation) => variation.ProductSizes
    ).find((size) => size.SizeID === parseInt(key));
    if (selectedSize && selectedSize.ProductImages.length > 0) {
      setSelectedImage(selectedSize.ProductImages[0].ImageURL);
    } else {
      setSelectedImage(null);
    }
  };

  const handleAddVariation = async () => {
    if (newVariationName.trim() === "") return;

    try {
      const response = await callApi("variations", "POST", {
        ProductID: selectedProduct.ProductID,
        Variation: newVariationName.trim(),
      });

      if (response.error) {
        message.error("Chyba při přidávání variace");
      } else {
        message.success("Variace přidána úspěšně");
        fetchProductById(selectedProduct.ProductID);
        setNewVariationName("");
        setAddingVariation(false);
      }
    } catch (error) {
      message.error("Chyba při přidávání variace");
    }
  };

  const handleAddSize = async (variationId) => {
    if (newSizeName.trim() === "") return;

    try {
      const response = await callApi("sizes", "POST", {
        VariationID: variationId,
        Size: newSizeName.trim(),
      });

      if (response.error) {
        message.error("Chyba při přidávání velikosti");
      } else {
        message.success("Velikost přidána úspěšně");
        fetchProductById(selectedProduct.ProductID);
        setNewSizeName("");
        setAddingSize(false);
      }
    } catch (error) {
      message.error("Chyba při přidávání velikosti");
    }
  };

  const handleDeleteVariation = async (variationId) => {
    try {
      const response = await callApi(`variations/${variationId}`, "DELETE");

      if (response.error) {
        message.error("Chyba při odstraňování variace");
      } else {
        message.success("Variace úspěšně odstraněna");
        fetchProductById(selectedProduct.ProductID);
        setActiveTabKey(
          selectedProduct.ProductVariations.sort((a, b) =>
            a.Variation.localeCompare(b.Variation)
          )[0]?.VariationID || null
        );
      }
    } catch (error) {
      message.error("Chyba při odstraňování variace");
    }
  };

  const handleDeleteSize = async (sizeId) => {
    try {
      const response = await callApi(`sizes/${sizeId}`, "DELETE");

      if (response.error) {
        message.error("Chyba při odstraňování velikosti");
      } else {
        message.success("Velikost úspěšně odstraněna");
        fetchProductById(selectedProduct.ProductID);
        setActiveSizeKey(
          selectedProduct.ProductVariations.flatMap(
            (variation) => variation.ProductSizes
          ).sort((a, b) => a.Size.localeCompare(b.Size))[0]?.SizeID || null
        );
      }
    } catch (error) {
      message.error("Chyba při odstraňování velikosti");
    }
  };

  const handleDeleteProduct = async () => {
    if (!selectedProduct?.ProductID) return;

    try {
      const response = await callApi(
        `products/${selectedProduct.ProductID}`,
        "DELETE"
      );

      if (response.error) {
        message.error("Chyba při odstraňování produktu");
      } else {
        message.success("Produkt úspěšně odstraněn");
        setProducts(
          products.filter((p) => p.ProductID !== selectedProduct.ProductID)
        );
        setModalVisible(false);
        setSelectedProduct(null);
      }
    } catch (error) {
      message.error("Chyba při odstraňování produktu");
    }
  };

  const handleFilterCategory = (category) => {
    setFilteredCategory(category);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = products.filter((product) => {
    const matchesCategory =
      filteredCategory === null || product.Category === filteredCategory;
    const matchesSearchTerm = product.ProductName.toLowerCase().includes(
      searchTerm.toLowerCase()
    );
    return matchesCategory && matchesSearchTerm;
  });

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    width: 450,
    height: 200,
    fluid: true,
    sources: [
      {
        src: "https://api.tomasdinh.cz/public/videos/BrownSugar.mp4",
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    console.log("Player is ready:", player);
  };

  const columns = [
    {
      title: " ",
      dataIndex: ["ProductVariations", "ProductSizes"],
      key: "Thumbnail",
      width: 50,
      render: (_, record) => {
        const firstVariation = record.ProductVariations[0];
        const firstSize = firstVariation?.ProductSizes[0];
        const imageUrl = firstSize?.ProductImages[0]?.ThumbnailURL;
        return imageUrl ? (
          <img
            width={50}
            src={`${url}${imageUrl}`}
            alt="Thumbnail"
            style={{ objectFit: "cover" }}
          />
        ) : (
          "No Image"
        );
      },
    },
    {
      title: "Produkt",
      dataIndex: "ProductName",
      key: "ProductName",
      width: 200,
      sorter: (a, b) => a.ProductName.localeCompare(b.ProductName),
    },
    {
      title: "Kategorie",
      dataIndex: "Category",
      key: "Category",
      width: 200,
      filters: [...new Set(products.map((product) => product.Category))].map(
        (category) => ({ text: category, value: category })
      ),
      onFilter: (value, record) => record.Category.includes(value),
    },
    {
      title: "Sezóna",
      dataIndex: "Season",
      key: "Season",
      width: 200,
      sorter: (a, b) => a.Season.localeCompare(b.Season),
      filters: [...new Set(products.map((product) => product.Season))].map(
        (season) => ({ text: season, value: season })
      ),
      onFilter: (value, record) => record.Season.includes(value),
    },
    {
      title: "Aktivní",
      dataIndex: "Active",
      key: "Active",
      width: 200,
      render: (text) => (text ? "✔️" : "❌"),
      sorter: (a, b) => a.Active - b.Active,
      filters: [
        { text: "✔️", value: true },
        { text: "❌", value: false },
      ],
      onFilter: (value, record) => record.Active === value,
    },
  ];

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert message={`Chyba: ${error}`} type="error" />;
  }

  const createSizeTabs = (sizes, variationId) => {
    const sortedSizes = sizes.sort((a, b) => a.Size.localeCompare(b.Size));

    return [
      ...sortedSizes.map((size) => ({
        label: size.Size,
        key: size.SizeID,
        children: (
          <div style={{ minHeight: "416px" }}>
            {size.Recipes.map((recipe) => (
              <div key={`recipe-${recipe.RecipeID}`}>
                <div style={{ paddingBottom: 30 }}>
                  <h5>Popis:</h5>
                  <p>{recipe.Description}</p>
                  <br />
                  <h5>Ingredience:</h5>
                  <ul style={{ paddingLeft: 20 }}>
                    {recipe.RecipeIngredients &&
                      recipe.RecipeIngredients.map((ingredient) => {
                        const key = ingredient.Supply
                          ? `supply-${ingredient.SupplyID}`
                          : `intermediate-${ingredient.IntermediateProductID}`;
                        return (
                          <li key={key}>
                            {ingredient.Supply ? (
                              <span>
                                {ingredient.Supply.Name}: {ingredient.Quantity}{" "}
                                {ingredient.Supply.Unit1}
                              </span>
                            ) : ingredient.IntermediateProduct ? (
                              <span>
                                {ingredient.IntermediateProduct.Name}:{" "}
                                {ingredient.Quantity}{" "}
                                {ingredient.IntermediateProduct.Unit}
                              </span>
                            ) : null}
                          </li>
                        );
                      })}
                  </ul>
                  <br />
                  <h5>Postup:</h5>
                  <p>{recipe.Preparation}</p>
                  <br />
                  <h5>Poznámky:</h5>
                  <p>{recipe.Note}</p>
                  <div
                    style={{
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    {/*   <VideoPlayer
                      props={{
                        options: videoJsOptions,
                        onReady: handlePlayerReady,
                      }}
                    /> */}
                  </div>
                </div>

                <div>
                  <Button
                    onClick={() => handleRecipeClick(recipe)}
                    style={{ position: "absolute", bottom: 0 }}
                    disabled={isEmployee}
                  >
                    Upravit
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ),
      })),
      {
        label: addingSize ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              value={newSizeName}
              onChange={(e) => setNewSizeName(e.target.value)}
              style={{ marginRight: 8 }}
              // Povolení mezer
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.stopPropagation();
                }
              }}
            />
            <CheckOutlined
              onClick={() => handleAddSize(variationId)}
              style={{ marginRight: 8 }}
            />
            <CloseOutlined
              onClick={() => setAddingSize(false)}
              style={{ marginRight: 8 }}
            />
          </div>
        ) : (
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={() => setAddingSize(true)}
            disabled={isEmployee}
            style={{
              color: isEmployee ? "rgba(0, 0, 0, 0.25)" : "inherit",
              cursor: isEmployee ? "not-allowed" : "pointer",
              marginTop: -5,
            }}
          />
        ),
        key: "add-size",
      },
    ];
  };

  const createVariationTabs = (variations) => {
    if (!Array.isArray(variations)) return [];

    const sortedVariations = variations.sort((a, b) =>
      a.Variation.localeCompare(b.Variation)
    );

    return [
      ...sortedVariations.map((variation) => ({
        label: variation.Variation,
        key: variation.VariationID,
        children: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Tabs
              items={createSizeTabs(
                variation.ProductSizes,
                variation.VariationID
              )}
              onChange={handleSizeTabChange}
              activeKey={activeSizeKey}
            />
            {activeSizeKey && variation.ProductSizes.length > 0 && (
              <Popconfirm
                title="Jsi si opravdu jist?"
                onConfirm={() => handleDeleteSize(activeSizeKey)}
                okText="Ano"
                cancelText="Ne"
              >
                <Button
                  type="text"
                  danger
                  icon={<MinusOutlined />}
                  style={{ marginLeft: 8, marginTop: 8 }}
                  disabled={isEmployee}
                />
              </Popconfirm>
            )}
          </div>
        ),
      })),
      {
        label: addingVariation ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              value={newVariationName}
              onChange={(e) => setNewVariationName(e.target.value)}
              style={{ marginRight: 8 }}
              // Povolení mezer
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.stopPropagation();
                }
              }}
            />
            <CheckOutlined
              onClick={handleAddVariation}
              style={{ marginRight: 8 }}
            />
            <CloseOutlined
              onClick={() => setAddingVariation(false)}
              style={{ marginRight: 8 }}
            />
          </div>
        ) : (
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={() => setAddingVariation(true)}
            disabled={isEmployee}
            style={{
              color: isEmployee ? "rgba(0, 0, 0, 0.25)" : "inherit",
              cursor: isEmployee ? "not-allowed" : "pointer",
              marginTop: -5,
            }}
          />
        ),
        key: "add-variation",
      },
    ];
  };

  const tabItems = [
    {
      label: "Recepty",
      key: "1",
      children: (
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 15,
              marginTop: 5,
            }}
          >
            <div>
              <Button
                type="primary"
                style={{ marginRight: 8 }}
                onClick={() => setNewProductModalVisible(true)}
                disabled={isEmployee}
              >
                Přidat nový produkt
              </Button>
              {[...new Set(products.map((product) => product.Category))]
                .sort()
                .map((category) => (
                  <Button
                    key={category}
                    onClick={() => handleFilterCategory(category)}
                    style={{
                      marginRight: 8,
                      backgroundColor:
                        filteredCategory === category ? "#1890ff" : "",
                      color: filteredCategory === category ? "#fff" : "",
                    }}
                  >
                    {category}
                  </Button>
                ))}
              <Button
                onClick={() => handleFilterCategory(null)}
                style={{
                  backgroundColor: filteredCategory === null ? "#1890ff" : "",
                  color: filteredCategory === null ? "#fff" : "",
                }}
              >
                Vše
              </Button>
            </div>
            <Input.Search
              placeholder="Vyhledávání..."
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 185 }}
            />
          </div>
          <Table
            columns={columns}
            dataSource={filteredProducts}
            rowKey="ProductID"
            pagination={{
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              defaultPageSize: 10,
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              onMouseEnter: (e) => {
                e.currentTarget.style.fontWeight = "bold";
                e.currentTarget.style.cursor = "pointer";
              },
              onMouseLeave: (e) => {
                e.currentTarget.style.fontWeight = "normal";
                e.currentTarget.style.cursor = "default";
              },
            })}
          />
        </Card>
      ),
    },
    {
      label: "Příprava",
      key: "2",
      children: <IntermediateProductRecipes />,
    },
    {
      label: "Produkty/Ingredience",
      key: "3",
      children: <Ingredients />,
    },
  ];

  return (
    <div className="TabsCard">
      <Tabs items={tabItems} />
      {selectedProduct && (
        <Modal
          title={selectedProduct.ProductName}
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          width={800}
        >
          <Row gutter={16}>
            <Col span={8}>
              {selectedImage ? (
                <>
                  <Image
                    src={`${url}${selectedImage}`}
                    alt="Selected"
                    style={{ width: "100%" }}
                  />
                </>
              ) : (
                <>
                  <p>Žádný obrázek není k dispozici</p>
                </>
              )}
            </Col>
            <Col span={16}>
              <div style={{ display: "flex", minHeight: "550px" }}>
                <Tabs
                  items={createVariationTabs(selectedProduct.ProductVariations)}
                  onChange={handleTabChange}
                  activeKey={activeTabKey}
                />
                <Popconfirm
                  title="Jsi si opravdu jist?"
                  onConfirm={handleDeleteProduct}
                  okText="Ano"
                  cancelText="Ne"
                >
                  <Button
                    type="text"
                    danger
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 10,
                    }}
                    disabled={isEmployee}
                  >
                    Odstranit produkt
                  </Button>
                </Popconfirm>
                {activeTabKey && (
                  <Popconfirm
                    title="Jsi si opravdu jist?"
                    onConfirm={() => handleDeleteVariation(activeTabKey)}
                    okText="Ano"
                    cancelText="Ne"
                  >
                    <Button
                      type="text"
                      danger
                      icon={<MinusOutlined />}
                      style={{ marginTop: 7 }}
                      disabled={isEmployee}
                    />
                  </Popconfirm>
                )}
              </div>
            </Col>
          </Row>
        </Modal>
      )}
      <EditRecipeModal
        visible={!!selectedRecipe}
        onCancel={() => {
          setSelectedRecipe(null);
          setModalVisible(false);
        }}
        selectedRecipe={selectedRecipe}
        supplies={supplies}
        onRecipeUpdated={(updatedProducts) => setProducts(updatedProducts)}
        setModalVisible={setModalVisible}
        selectedProduct={selectedProduct}
        key={selectedRecipe?.RecipeID}
      />
      <AddNewProductModal
        visible={newProductModalVisible}
        onCancel={() => setNewProductModalVisible(false)}
        onSubmit={handleAddNewProduct}
      />
    </div>
  );
}

export default Recipe;
