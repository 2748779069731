import React, { useEffect, useState } from "react";
import {
  Table,
  Card,
  message,
  Input,
  Modal,
  Row,
  Col,
  Image,
  Button,
  Popconfirm,
} from "antd";
import callApi from "../../ApiCaller";
import EditIngredientModal from "./EditIngredientModal";
import AddIngredientModal from "./AddIngredientModal";

import { useAuth } from "oidc-react";

const Ingredients = () => {
  const [ingredients, setIngredients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCategory, setFilteredCategory] = useState(null);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const url = "https://api.tomasdinh.cz/public/";
  const auth = useAuth();
  console.log(error);
  const isEmployee = auth.userData?.profile?.[
    "urn:zitadel:iam:org:project:284092046917005314:roles"
  ]?.some((role) => role.employee);

  const fetchIngredients = async () => {
    try {
      setLoading(true);
      const response = await callApi("supplies", "GET");

      if (response.error) {
        setError(response.message);
      } else {
        setIngredients(response);
      }
    } catch (error) {
      setError(error.message);
      message.error("Chyba při načítání dat");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIngredients();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterCategory = (category) => {
    setFilteredCategory(category);
  };

  const handleRowClick = (record) => {
    setSelectedIngredient(record);
    setDetailModalVisible(true);
  };

  const handleUpdate = async () => {
    await fetchIngredients();
    setEditModalVisible(false);
  };

  const handleAdd = (newIngredient) => {
    setIngredients((prevIngredients) => [...prevIngredients, newIngredient]);
  };

  const handleDeleteIngredient = async (ingredientId) => {
    try {
      // Zavolej nový endpoint pro kontrolu
      const checkResponse = await callApi(
        `supplies/check/${ingredientId}`,
        "GET"
      );

      if (checkResponse.error) {
        message.error(
          checkResponse.message ||
            "Ingredience je používána v receptu, nejdříve ji odstraňte z receptu."
        );
        return;
      }

      // Pokud ingredience není používána, pokračuj s mazáním
      const deleteResponse = await callApi(
        `supplies/${ingredientId}`,
        "DELETE"
      );

      if (deleteResponse.error) {
        message.error(
          deleteResponse.message || "Chyba při odstraňování ingredience"
        );
      } else {
        setIngredients((prevIngredients) =>
          prevIngredients.filter(
            (ingredient) => ingredient.SupplyID !== ingredientId
          )
        );
        message.success("Ingredience úspěšně odstraněna");
        setDetailModalVisible(false);
      }
    } catch (error) {
      message.error(error.message || "Chyba při odstraňování ingredience");
    }
  };

  const filteredIngredients = ingredients.filter((ingredient) => {
    const matchesCategory =
      filteredCategory === null || ingredient.Category === filteredCategory;
    const matchesSearchTerm = ingredient.Name
      ? ingredient.Name.toLowerCase().includes(searchTerm.toLowerCase())
      : false;
    return matchesCategory && matchesSearchTerm;
  });

  const columns = [
    {
      title: "",
      dataIndex: "SupplyImages",
      key: "Thumbnail",
      width: 50,
      render: (images) => {
        const imageUrl =
          images && images.length > 0
            ? `${url}${images[0].ThumbnailURL}`
            : null;
        return imageUrl ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <img
              key={`${images[0].ImageID}-thumbnail`}
              src={imageUrl}
              alt="Thumbnail"
              style={{ height: "100px" }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <img
              src="https://api.tomasdinh.cz/public/images/noImage.webp"
              alt="Thumbnail"
              style={{ height: "150px" }}
            />
          </div>
        );
      },
    },
    {
      title: "Název ingredience",
      dataIndex: "Name",
      key: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      width: 300,
    },
    {
      title: "Kategorie",
      dataIndex: "Category",
      key: "Category",
      sorter: (a, b) => a.Category.localeCompare(b.Category),
      filters: [
        ...new Set(ingredients.map((ingredient) => ingredient.Category)),
      ]
        .filter((category) => category)
        .map((category) => ({ text: category, value: category })),
      onFilter: (value, record) => record.Category === value,
    },
    {
      title: "Jednotka 1",
      dataIndex: "Unit1",
      key: "Unit1",
      sorter: (a, b) => a.Unit1.localeCompare(b.Unit1),
      filters: [...new Set(ingredients.map((ingredient) => ingredient.Unit1))]
        .filter((unit) => unit)
        .map((unit) => ({ text: unit, value: unit })),
      onFilter: (value, record) => record.Unit1 === value,
    },
    {
      title: "Jednotka 2",
      dataIndex: "Unit2",
      key: "Unit2",
      sorter: (a, b) => a.Unit2.localeCompare(b.Unit2),
      filters: [...new Set(ingredients.map((ingredient) => ingredient.Unit2))]
        .filter((unit) => unit)
        .map((unit) => ({ text: unit, value: unit })),
      onFilter: (value, record) => record.Unit2 === value,
    },
    {
      title: "Konverzní Faktor",
      dataIndex: "ConversionFactor",
      key: "ConversionFactor",
      sorter: (a, b) => a.ConversionFactor - b.ConversionFactor,
    },
  ];

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 15,
          marginTop: 5,
        }}
      >
        <div>
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => setAddModalVisible(true)}
            disabled={isEmployee}
          >
            Přidat novou ingredienci
          </Button>
          {[...new Set(ingredients.map((ingredient) => ingredient.Category))]
            .sort()
            .map((category) => (
              <Button
                key={category}
                onClick={() => handleFilterCategory(category)}
                style={{
                  marginRight: 8,
                  backgroundColor:
                    filteredCategory === category ? "#1890ff" : "",
                  color: filteredCategory === category ? "#fff" : "",
                }}
              >
                {category}
              </Button>
            ))}
          <Button
            key="all"
            onClick={() => handleFilterCategory(null)}
            style={{
              backgroundColor: filteredCategory === null ? "#1890ff" : "",
              color: filteredCategory === null ? "#fff" : "",
            }}
          >
            Vše
          </Button>
        </div>
        <Input.Search
          placeholder="Vyhledávání..."
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={filteredIngredients}
        rowKey="SupplyID"
        loading={loading}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          onMouseEnter: (e) => {
            e.currentTarget.style.fontWeight = "bold";
            e.currentTarget.style.cursor = "pointer";
          },
          onMouseLeave: (e) => {
            e.currentTarget.style.fontWeight = "normal";
            e.currentTarget.style.cursor = "default";
          },
        })}
      />
      {selectedIngredient && (
        <Modal
          title={selectedIngredient.Name}
          open={detailModalVisible}
          onCancel={() => setDetailModalVisible(false)}
          footer={null}
          width={800}
        >
          <Row gutter={16}>
            <Col
              span={8}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {selectedIngredient?.SupplyImages?.[0]?.ImageURL ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                  
                    key={`${selectedIngredient.SupplyID}-image`}
                    src={`${url}${selectedIngredient.SupplyImages[0].ImageURL}`}
                    alt={selectedIngredient.Name}
                    style={{ maxHeight: "500px" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "500px",
                  }}
                >
                  <img
                    src="https://api.tomasdinh.cz/public/images/noImage.webp"
                    alt="no img"
                    style={{ height: "400px" }}
                  />
                </div>
              )}
            </Col>
            <Col span={16}>
              <div style={{ minHeight: "400px" }}>
                <h5>Kategorie</h5>
                <p>{selectedIngredient.Category}</p>
                <br />
                <h5>Jednotka 1</h5>
                <p>{selectedIngredient.Unit1}</p>
                <br />
                <h5>Jednotka 2</h5>
                <p>{selectedIngredient.Unit2}</p>
                <br />
                <h5>Konverzní Faktor</h5>
                <p>{selectedIngredient.ConversionFactor}</p>

                <br />
                <Button
                  onClick={() => {
                    setEditModalVisible(true);
                    setDetailModalVisible(false);
                  }}
                  style={{ position: "absolute", bottom: 0 }}
                  disabled={isEmployee}
                >
                  Upravit
                </Button>
                <div>
                  <Popconfirm
                    title="Jste si opravdu jisti?"
                    onConfirm={() =>
                      handleDeleteIngredient(selectedIngredient.SupplyID)
                    }
                    okText="Ano"
                    cancelText="Ne"
                  >
                    <Button
                      type="text"
                      danger
                      style={{ position: "absolute", bottom: 0, right: 10 }}
                      disabled={isEmployee}
                    >
                      Odstranit ingredienci
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      )}
      {selectedIngredient && (
        <EditIngredientModal
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          onUpdate={handleUpdate}
          ingredient={selectedIngredient}
        />
      )}
      <AddIngredientModal
        visible={addModalVisible}
        onCancel={() => {
          setAddModalVisible(false);
          fetchIngredients();
        }}
        onAdd={handleAdd}
      />
    </Card>
  );
};

export default Ingredients;
