import React, { useState, useEffect } from "react";
import {
  Table,
  Select,
  Input,
  DatePicker,
  notification,
  Modal,
  Upload,
  Button,
  Tooltip,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useSocket } from "../../SocketProvider";
import Papa from "papaparse";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";

import "./bankTransaction.scss";

dayjs.extend(isBetween);
const BankTransaction = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [editingSubcategory, setEditingSubcategory] = useState({
    rowId: null,
    categoryId: null,
  });
  const socket = useSocket();
  const Dragger = Upload.Dragger;

  useEffect(() => {
    setLoading(true);
    const fetchBankTransactionData = () => {
      socket.emit("bank_transaction", {
        operation: "READ",
        payload: { someValue: "*" },
      });
    };
    fetchBankTransactionData();
    socket.on("bank_transaction_data", (rows) => {
      const formattedData = rows.map((row) => ({
        ...row,
        key: row.ID,
        Datum_provedeni: dayjs(row.Datum_provedeni, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        ),
      }));
      setData(formattedData);
    });

    socket.on("bank_transaction_upload_success", (data) => {
      if (data.success) {
        notification.success({
          message: "Platební transakce byli aktualizovány",
          placement: "bottomRight",
          duration: 2,
        });
        fetchBankTransactionData();
      } else {
        notification.error({
          message: "Chyba při nahrávání CSV souboru do databáze",
          placement: "bottomRight",
          duration: 3,
        });
      }
    });

    socket.on("bank_transaction_no_new_data", () => {
      notification.info({
        message: "Žádná nové platby k aktualizaci",
        placement: "bottomRight",
        duration: 3,
      });
    });

    socket.on("bank_transaction_update_category_success", (data) => {
      if (data.success) {
        notification.success({
          message: "Kategorie byla aktualizována",
          placement: "bottomRight",
          duration: 2,
        });
        fetchBankTransactionData();
      } else {
        notification.error({
          message: "Chyba při aktualizaci kategorie",
          placement: "bottomRight",
          duration: 3,
        });
      }
    });

    // Fetch categories and subcategories
    socket.emit("pos_transaction", {
      operation: "FETCH_CATEGORIES_SUBCATEGORIES",
    });

    socket.on("categories_subcategories_data", (categories) => {
      let categoryOptions = categories
        .filter((c) => c.ID_Parent === null)
        .map((category) => ({ label: category.Name, value: category.ID }));

      let subCategoryOptions = categories
        .filter((c) => c.ID_Parent !== null)
        .map((subCategory) => ({
          label: subCategory.Name,
          value: subCategory.ID,
          parent: subCategory.ID_Parent,
        }));

      // Kontrola, zda "Nezařazeno" již existuje, než ho přidáme
      if (!subCategoryOptions.some((option) => option.value === 37)) {
        subCategoryOptions.push({
          label: "Nezařazeno",
          value: 37,
          parent: null,
        });
      }

      // Seřazení a aktualizace stavu
      const sortOptions = (a, b) =>
        a.label === "Nezařazeno"
          ? -1
          : b.label === "Nezařazeno"
          ? 1
          : a.label.localeCompare(b.label);
      categoryOptions = categoryOptions.sort(sortOptions);
      subCategoryOptions = subCategoryOptions.sort(sortOptions);

      setCategoryOptions(categoryOptions);
      setSubCategoryOptions(subCategoryOptions);

      setLoading(false);
    });

    return () => {
      socket.off("bank_transaction_data");
      socket.off("bank_transaction_update_category_success");
      socket.off("bank_transaction_no_new_data");
      socket.off("bank_transaction_upload_success");
      socket.off("categories_subcategories_data");
    };
  }, [socket]);

  const handleCategoryChange = (newCategoryId, record) => {
    const updatedData = data.map((item) => {
      if (item.ID === record.ID) {
        return { ...item, ID_Kategorie: newCategoryId };
      }
      return item;
    });
    setData(updatedData);
    socket.emit("bank_transaction", {
      operation: "UPDATE_CATEGORY",
      payload: { ID: record.ID, newCategoryId: newCategoryId },
    });
    setEditingCategoryId(null);
  };

  const handleSubcategoryChange = (newSubcategoryId, record) => {
    const updatedData = data.map((item) => {
      if (item.ID === record.ID) {
        return { ...item, ID_Podkategorie: newSubcategoryId };
      }
      return item;
    });
    setData(updatedData);
    socket.emit("bank_transaction", {
      operation: "UPDATE_SUBCATEGORY",
      payload: { ID: record.ID, newSubcategoryId: newSubcategoryId },
    });
    setEditingSubcategory({ rowId: null, categoryId: null });
  };

  const uniqueCategories = Array.from(
    new Set(data.map((item) => item.ID_Kategorie))
  );
  const uniqueSubcategories = Array.from(
    new Set(data.map((item) => item.ID_Podkategorie))
  );
  const uniqueCategorieTransaction = Array.from(
    new Set(data.map((item) => item.Kategorie_transakce))
  );
  const uniqueTypeTransaction = Array.from(
    new Set(data.map((item) => item.Typ_transakce))
  );
  const uniqueCurrency = Array.from(
    new Set(data.map((item) => item.Mena_uctu))
  );

  const categoryIdToNameMap = categoryOptions.reduce((map, option) => {
    map[option.value] = option.label;
    return map;
  }, {});

  const categoryFilters = uniqueCategories.map((categoryId) => ({
    text: categoryIdToNameMap[categoryId],
    value: categoryId,
  }));
  const subCategoryIdToNameMap = subCategoryOptions.reduce((map, option) => {
    map[option.value] = option.label;
    return map;
  }, {});
  const subCategoryFilters = uniqueSubcategories.map((subcategoryId) => ({
    text: subCategoryIdToNameMap[subcategoryId],
    value: subcategoryId,
  }));

  const categoryTransaction = uniqueCategorieTransaction
    .sort()
    .map((categoryTransaction) => ({
      text: categoryTransaction,
      value: categoryTransaction,
    }));
  const TypeTransactionFilters = uniqueTypeTransaction.map((transaction) => ({
    text: transaction,
    value: transaction,
  }));
  const currencyOfAccount = uniqueCurrency.map((currency) => ({
    text: currency,
    value: currency,
  }));

  const filteredData = data.filter((item) => {
    const {
      Cislo_protiuctu,
      Zauctovana_castka,
      Nazev_protiuctu,
      Nazev_obchodnika,
      VS,
    } = item;
    const searchTextLower = searchText ? searchText.toLowerCase() : "";

    // Filtration by text
    const textMatch =
      (Cislo_protiuctu &&
        Cislo_protiuctu.toLowerCase().includes(searchTextLower)) ||
      (Zauctovana_castka &&
        Zauctovana_castka.toLowerCase().includes(searchTextLower)) ||
      (Nazev_protiuctu &&
        Nazev_protiuctu.toLowerCase().includes(searchTextLower)) ||
      (Nazev_obchodnika &&
        Nazev_obchodnika.toLowerCase().includes(searchTextLower)) ||
      (VS && VS.toLowerCase().includes(searchTextLower));

    // Filtration by date
    const dateMatch =
      !selectedDateRange[0] ||
      !selectedDateRange[1] ||
      (item.Datum_provedeni &&
        dayjs(item.Datum_provedeni).isBetween(
          dayjs(selectedDateRange[0]),
          dayjs(selectedDateRange[1]),
          null,
          "[]"
        ));

    return textMatch && dateMatch;
  });

  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const handleUploadModalCancel = () => {
    setIsUploadModalVisible(false);
  };
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDateRange(dates);
    } else {
      setSelectedDateRange([null, null]);
    }
  };

  const columns = [
    {
      title: "Id transakce",
      dataIndex: "Id_transakce",
      key: "	Id_transakce",
      width: 100,
      sorter: (a, b) => a.Id_transakce - b.Id_transakce,
      showSorterTooltip: false,
    },
    {
      title: "Datum provedení",
      dataIndex: "Datum_provedeni",
      key: "Datum_provedeni",
      width: 100,
      sorter: (a, b) => {
        const dateA = new Date(a.Datum_provedeni);
        const dateB = new Date(b.Datum_provedeni);
        return dateA - dateB;
      },
      defaultSortOrder: "desc",
      render: (text) => dayjs(text).format("DD. MM. YYYY"),
      showSorterTooltip: false,
    },

    {
      title: "Datum zaúčtování",
      dataIndex: "Datum_zauctovani",
      key: "Datum_zauctovani",
      width: 100,
    },

    {
      title: "Číslo účtu",
      dataIndex: "Cislo_uctu",
      key: "Cislo_uctu",
      width: 120,
    },
    {
      title: "Kategorie transakce",
      dataIndex: "Kategorie_transakce",
      key: "Kategorie_transakce",
      width: 100,
      filters: categoryTransaction,
      onFilter: (value, record) => record.Kategorie_transakce === value,
    },
    {
      title: "Název protiúčtu",
      dataIndex: "Nazev_protiuctu",
      key: "Nazev_protiuctu",
      width: 100,
    },
    {
      title: "Číslo protiúčtu",
      dataIndex: "Cislo_protiuctu",
      key: "Cislo_protiuctu",
      width: 200,
    },
    {
      title: "Kategorie",
      dataIndex: "ID_Kategorie",
      key: "Kategorie",
      filters: categoryFilters,
      onFilter: (value, record) => record.ID_Kategorie === value,
      render: (text, record) => {
        const category = categoryOptions.find(
          (c) => c.value === record.ID_Kategorie
        );
        const categoryName = category ? category.label : "Nezařazeno";
        return editingCategoryId === record.key ? (
          <Select
            showSearch={false}
            style={{ width: 200 }}
            placeholder="Vyberte kategorii"
            defaultValue={text}
            onChange={(value) => handleCategoryChange(value, record)}
            onBlur={() => setEditingCategoryId(null)}
          >
            {categoryOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <div
            onClick={() => setEditingCategoryId(record.key)}
            style={{ cursor: "pointer" }}
          >
            {categoryName}
          </div>
        );
      },
    },
    {
      title: "Podkategorie",
      dataIndex: "ID_Podkategorie",
      key: "Podkategorie",
      filters: subCategoryFilters,
      onFilter: (value, record) => record.ID_Podkategorie === value,

      render: (text, record) => {
        const subCategory = subCategoryOptions.find(
          (c) => c.value === record.ID_Podkategorie
        );
        const subCategoryName = subCategory ? subCategory.label : "Nezařazeno";

        return editingSubcategory.rowId === record.key ? (
          <Select
            showSearch={false}
            style={{ width: 200 }}
            placeholder="Vyberte podkategorii"
            defaultValue={record.ID_Podkategorie}
            onChange={(value) => handleSubcategoryChange(value, record)}
            onBlur={() =>
              setEditingSubcategory({ rowId: null, categoryId: null })
            }
          >
            {subCategoryOptions
              .filter(
                (option) =>
                  option.parent === record.ID_Kategorie || option.value === 37
              )
              .map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        ) : (
          <div
            onClick={() =>
              setEditingSubcategory({
                rowId: record.key,
                categoryId: record.ID_Kategorie,
              })
            }
            style={{ cursor: "pointer" }}
          >
            {subCategoryName}
          </div>
        );
      },
    },
    {
      title: "Typ transakce",
      dataIndex: "Typ_transakce",
      key: "Typ_transakce",
      filters: TypeTransactionFilters,
      onFilter: (value, record) => record.Typ_transakce === value,
    },
    {
      title: "Variabilní číslo",
      dataIndex: "VS",
      key: "VS",
    },

    {
      title: "Poznámka",
      dataIndex: "Poznamka",
      key: "Poznamka",
    },
    {
      title: "Název obchodníka",
      dataIndex: "Nazev_obchodnika",
      key: "Nazev_obchodnika",
    },
    {
      title: "Zaúčtovaná částka",
      dataIndex: "Zauctovana_castka",
      key: "Zauctovana_castka",
      sorter: (a, b) => {
        const valueA = parseFloat(a.Zauctovana_castka) || 0;
        const valueB = parseFloat(b.Zauctovana_castka) || 0;
        return valueA - valueB;
      },
      showSorterTooltip: false,
    },
    {
      title: "Měna účtu",
      dataIndex: "Mena_uctu",
      key: "Mena_uctu",
      filters: currencyOfAccount,
      onFilter: (value, record) => record.Mena_uctu === value,
    },
    {
      title: "Poplatek v měně transakce",
      dataIndex: "Poplatek",
      key: "Poplatek",
      sorter: (a, b) => {
        const valueA = parseFloat(a.Poplatek) || 0;
        const valueB = parseFloat(b.Poplatek) || 0;
        return valueA - valueB;
      },
      showSorterTooltip: false,
    },
    {
      title: "Původní částka a měna",
      dataIndex: "Puvodni_castka_a_mena",
      key: "Puvodni_castka_a_mena",
      sorter: (a, b) => {
        // Ošetření null hodnot
        const valueA = a.Puvodni_castka_a_mena || "";
        const valueB = b.Puvodni_castka_a_mena || "";
        return valueA.localeCompare(valueB);
      },
      showSorterTooltip: false,
    },
    // {
    //   title: "Id",
    //   dataIndex: "ID",
    //   key: "ID",
    // },
    // {
    //   title: "Název účtu",
    //   dataIndex: "Nazev_uctu",
    //   key: "Nazev_uctu",
    // },
    // {
    //   title: "Konstantní číslo",
    //   dataIndex: "KS",
    //   key: "KS",
    // },
    // {
    //   title: "Specifické číslo",
    //   dataIndex: "SS",
    //   key: "SS",
    // },
    // {
    //   title: "Zpráva",
    //   dataIndex: "Zprava",
    //   key: "Zprava",
    // },
    // {
    //   title: "Původní částka a měna 1",
    //   dataIndex: "Puvodni_castka_a_mena_1",
    //   key: "Puvodni_castka_a_mena_1",
    //   sorter: (a, b) => {
    //     // Ošetření null hodnot
    //     const valueA = a.Puvodni_castka_a_mena_1 || "";
    //     const valueB = b.Puvodni_castka_a_mena_1 || "";
    //     return valueA.localeCompare(valueB);
    //   },
    // },

    // {
    //   title: "Vlastní poznámka",
    //   dataIndex: "Vlastni_poznamka",
    //   key: "Vlastni_poznamka",
    // },
    // {
    //   title: "Místo",
    //   dataIndex: "Misto",
    //   key: "Misto",
    // },
  ];

  /* FileUpload */
  const FileUpload = () => {
    const handleFileUpload = (file) => {
      setIsUploading(true);

      const reader = new FileReader();
      reader.onload = (e) => {
        const text = new TextDecoder("windows-1250").decode(e.target.result);
        Papa.parse(text, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (result) => {
            setIsUploading(false);
            socket.emit("bank_transaction", {
              operation: "UPLOAD_CSV",
              payload: result.data,
            });
            setUploadSuccess(true);
          },
          error: () => {
            setIsUploading(false);
            setUploadSuccess(false);
          },
        });
      };
      reader.readAsArrayBuffer(file);
    };

    return (
      <div>
        <Dragger
          accept=".csv"
          showUploadList={false}
          customRequest={({ file }) => handleFileUpload(file)}
          style={{ marginTop: 20 }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Klikněte pro nahrání souboru</p>
          <p className="ant-upload-hint">nebo přetáhněte soubor sem</p>
        </Dragger>
        {isUploading && <p>Nahrává se...</p>}
        {uploadSuccess && (
          <p style={{ textAlign: "center" }}>Nahrání úspěšné!</p>
        )}
      </div>
    );
  };
  /* End of FileUpload */

  return (
    <div>
      <h1>Bankovní Transakce</h1>

      <Tooltip title="Vyhledáváš v Číslo protiúčtu, Zaúčtovaná částka, Název protiúčtu, Název obchodníka a VS,">
        <Input
          placeholder="Vyhledat"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: "10px", width: "250px", marginRight: "45px" }}
        />
      </Tooltip>

      <DatePicker.RangePicker
        placeholder={["Od", "Do"]}
        locale={locale}
        value={selectedDateRange || []}
        onChange={handleDateChange}
        style={{
          marginRight: "45px",
          width: "250px",
        }}
      />
      <Button type="primary" onClick={showUploadModal}>
        Nahrát CSV soubor
      </Button>
      <Table
        dataSource={filteredData}
        columns={columns}
        loading={loading}
        rowClassName={(record) => {
          const subCategory = subCategoryOptions.find(
            (c) => c.value === record.ID_Podkategorie
          );
          const category = categoryOptions.find(
            (c) => c.value === record.ID_Kategorie
          );

          const isSubcategorySigned =
            subCategory && subCategory.label === "Nezařazeno";
          const isCategorySigned = category && category.label === "Nezařazeno";
          return isSubcategorySigned || isCategorySigned ? "row-danger" : "";
        }}
      />
    </div>
  );
};

export default BankTransaction;
