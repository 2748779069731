import React, { useState, useEffect } from "react";
import { Table, DatePicker, ConfigProvider } from "antd";
import { useSocket } from "../../SocketProvider";
import dayjs from "dayjs";
import DateRangeSelector from "../../utils/DateRangeSelector";
import "./salesByTaxes.scss";
import "dayjs/locale/cs";
import locale from "antd/lib/locale/cs_CZ";

const { RangePicker } = DatePicker;

dayjs.locale("cs");

const SalesByTaxes = () => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const socket = useSocket();

  const getYearStartEnd = () => {
    const currentYear = new Date().getFullYear();
    const startOfYear = `${currentYear}-01-01`;
    const endOfYear = `${currentYear}-12-31`;
    return [startOfYear, endOfYear];
  };

  const fetchSalesData = (startDate, endDate) => {
    // Konverze dat na požadovaný formát
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD 00:00:00");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD 23:59:59");

    socket.emit("fetch-sales-by-tax", {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });

    socket.on("sales-by-tax-data", (rows) => {
      const dataWithKeys = rows.map((row, index) => ({
        ...row,
        key: index,
      }));

      setData(dataWithKeys);
    });

    socket.on("sales-by-tax-error", (error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    const [startOfYear, endOfYear] = getYearStartEnd();
    fetchSalesData(startOfYear, endOfYear);

    return () => {
      socket.off("fetch-sales-by-tax");
      socket.off("sales-by-tax-data");
      socket.off("sales-by-tax-error");
    };
  }, []);

  const handleDateChange = (dates, dateStrings) => {
    if (dates && dates.length > 0) {
      setSelectedDate(dates);
      fetchSalesData(dateStrings[0], dateStrings[1]);
    } else {
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("cs-CZ", {
      style: "currency",
      currency: "CZK",
    }).format(value);
  };

  const columns = [
    {
      title: "Daň",
      dataIndex: "TaxName",
      key: "TaxName",
      render: (text, record) => `${text} (${record.TaxRate}%)`,
    },
    {
      title: "Celkem bez daně",
      dataIndex: "TotalWithoutTax",
      key: "TotalWithoutTax",
      render: (text) => formatCurrency(text),
    },
    {
      title: "Daň",
      dataIndex: "TotalTax",
      key: "TotalTax",
      render: (text) => formatCurrency(text),
    },
    {
      title: "Celkem s daní",
      dataIndex: "TotalWithTax",
      key: "TotalWithTax",
      render: (text) => formatCurrency(text),
    },
  ];

  const getTotalWithTaxSum = () => {
    const total = data.reduce((sum, record) => sum + record.TotalWithTax, 0);
    return formatCurrency(total);
  };

  return (
    <ConfigProvider locale={locale}>
      <div className="salesByTaxes">
        <h1 className="salesByTaxesH1">Prodeje dle daně</h1>{" "}
        <div className="salesByTaxesHeader">
          <div className="rangePicker">
            <RangePicker
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              defaultValue={getYearStartEnd().map(dayjs)}
            />
          </div>
          <div className="datePickers">
            <DateRangeSelector onDateChange={handleDateChange} />
          </div>
        </div>
        <div className="table">
          <Table
            dataSource={data}
            columns={columns}
            footer={() => (
              <div>
                <strong>Celkový prodej s daní: </strong>
                {getTotalWithTaxSum()}
              </div>
            )}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default SalesByTaxes;
