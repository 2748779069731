import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import Select from "react-select";
import { useAuth } from "oidc-react";
import MonthResetButton from "./MonthResetButton";
import callApi from "../../ApiCaller";
import "./MonthBigChartBox.scss";

const currentDay = new Date().getDate();
const months = [
  { value: 1, label: "Leden" },
  { value: 2, label: "Únor" },
  { value: 3, label: "Březen" },
  { value: 4, label: "Duben" },
  { value: 5, label: "Květen" },
  { value: 6, label: "Červen" },
  { value: 7, label: "Červenec" },
  { value: 8, label: "Srpen" },
  { value: 9, label: "Září" },
  { value: 10, label: "Říjen" },
  { value: 11, label: "Listopad" },
  { value: 12, label: "Prosinec" },
];

const MonthBigChartBox = () => {
  const [data, setData] = useState();
  const [selectedMonth, setSelectedMonth] = useState(
    months[new Date().getMonth()]
  );
  const auth = useAuth();
  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.[
    "urn:zitadel:iam:org:project:284092046917005314:roles"
  ]?.some((role) => role.employee);
  const isInvestor = userProfile?.[
    "urn:zitadel:iam:org:project:284092046917005314:roles"
  ]?.some((role) => role.investor);

  // Funkce pro reset na aktuální měsíc
  const resetToCurrentMonth = () => {
    const currentMonth = months[new Date().getMonth()];
    setSelectedMonth(currentMonth);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
  };
  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const titleStyle = {
    color: isHovered ? "teal" : "white", // Změna barvy při najetí myší
  };

  useEffect(() => {
    if (selectedMonth) {
      callApi(`monthly-data/${selectedMonth.value}`, "GET")
        .then((apiData) => {
          const { yearlyData } = apiData;
          const currentYear = new Date().getFullYear();
          const daysInMonth = getDaysInMonth(selectedMonth.value, currentYear);
          // Inicializace prázdných dat pro každý den a rok
          const chartData = Array.from({ length: daysInMonth }, (_, i) => {
            const dayData = { name: (i + 1).toString() };
            yearlyData.forEach((yearData) => {
              if (yearData.length > 0) {
                // Přidání kontroly zda pole obsahuje data
                dayData[yearData[0].year] = 0; // Inicializace roku s 0
              }
            });
            return dayData;
          });

          // Naplnění dat z API
          yearlyData.forEach((yearArray) => {
            if (yearArray.length > 0) {
              // Přidání kontroly zda pole obsahuje data
              yearArray.forEach((dataItem) => {
                const dayIndex = dataItem.day - 1;
                chartData[dayIndex][dataItem.year] = dataItem.dailyTotal;
              });
            }
          });

          setData(chartData);
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
        });
    }
  }, [selectedMonth]);

  const renderXAxisTick = (props) => {
    const { x, y, payload } = props;
    const day = parseInt(payload.value, 10);
    const isCurrentDay = day === currentDay;
    const textStyle = {
      fontWeight: isCurrentDay ? "bold" : "normal",
      fill: isCurrentDay ? "white" : "#666",
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          style={textStyle}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const yAxisStyle = {
    fontSize: 12,
  };

  const getStrokeColor = (year) => {
    const colors = {
      2021: "#8884d8", // fialová
      2022: "#ffc658", // oranžová
      2023: "#82ca9d", // světle zelená
      2024: "#70a1ff", //  modrá
    };
    return colors[year] || "#000000"; // Výchozí barva, pokud rok není v seznamu
  };

  return (
    <div className="bigChartBox">
      {!(isEmployee || isInvestor) ? (
        <Link
          to="/documents"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={titleStyle}
        >
          <h1>Denní tržba</h1>
        </Link>
      ) : (
        // Zobrazí se pouze text bez aktivního odkazu
        <h1 style={titleStyle}>Denní tržba</h1>
      )}
      <div className="select-and-reset">
        <Select
          className="select"
          options={months}
          value={selectedMonth}
          onChange={handleMonthChange}
          classNamePrefix="custom-select"
          styles={{
            control: (provided, state) => ({
              ...provided,
              color: "white",
              width: "150px",
              backgroundColor: "#222b3c",
              borderColor: "#222b3c",
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "white",
            }),
            menu: (provided, state) => ({
              ...provided,
              color: "white",
              backgroundColor: "#384256",
              borderColor: "#222b3c",
              marginTop: 0,
            }),
          }}
        />
        <MonthResetButton onResetToCurrentMonth={resetToCurrentMonth} />
      </div>
      <div className="chart">
        <ResponsiveContainer width="99%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis dataKey="name" tick={renderXAxisTick} />
            <YAxis style={yAxisStyle} />
            <Legend />
            <Tooltip
              content={(props) => {
                const { active, payload } = props;
                if (active && payload && payload.length > 0) {
                  const currentYear = new Date().getFullYear();
                  const previousYear = currentYear - 1;
                  let currentYearValue = 0;
                  let previousYearValue = 0;

                  const tooltipContent = payload.map((p) => {
                    // Extrahování roku z názvu (např. "Rok 2022" => 2022)
                    const year = parseInt(p.name.replace(/\D/g, ""));
                    const color = getStrokeColor(year);

                    // Přiřazení hodnot aktuálního a předchozího roku
                    if (year === currentYear) {
                      currentYearValue = p.value;
                    } else if (year === previousYear) {
                      previousYearValue = p.value;
                    }

                    return (
                      <p key={p.name} style={{ color: color }}>{`${
                        p.name
                      }: ${p.value.toLocaleString()} Kč`}</p>
                    );
                  });

                  const percentageDifference =
                    previousYearValue !== 0
                      ? ((currentYearValue - previousYearValue) /
                          previousYearValue) *
                        100
                      : 0;

                  tooltipContent.push(
                    <p key="percentageDifference">
                      {`Rozdíl mezi ${currentYear} a ${previousYear}: 
                        ${percentageDifference.toFixed(2)} 
                        %`}
                    </p>
                  );

                  return <div className="custom-tooltip">{tooltipContent}</div>;
                }
                return null;
              }}
            />

            {data &&
              Object.keys(data[0])
                .filter((key) => key !== "name")
                .map((year) => (
                  <Area
                    key={year}
                    type="monotone"
                    dataKey={year}
                    stroke={getStrokeColor(year)}
                    fill="transparent"
                    name={`Rok ${year}`}
                  />
                ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MonthBigChartBox;
