import React, { useEffect, useState } from "react";
import { notification } from "antd";
import "./DeviceControl.scss";
import { useAuth } from "oidc-react";

const fakeLevaClonaData = {
  isok: true,
  data: {
    device_status: {
      "switch:0": {
        temperature: { tC: 25 },
        output: true,
        errors: [],
      },
    },
  },
};

const fakePravaClonaData = {
  isok: true,
  data: {
    device_status: {
      "switch:0": {
        temperature: { tC: 26 },
        output: false,
        errors: [],
      },
    },
  },
};

const fakeIceMakerData = {
  isok: true,
  data: {
    device_status: {
      "switch:0": {
        temperature: { tC: -5 },
        output: false,
        errors: [],
      },
    },
  },
};

function DeviceControl() {
  const [isLoading, setIsLoading] = useState(true);
  const [levaClonaStatus, setLevaClonaStatus] = useState(null);
  const [isActiveLevaClona, setIsActiveLevaClona] = useState(false);
  const [tempLevaClona, setTempLevaClona] = useState(null);
  const [levaClonaErrorMessage, setLevaClonaErrorMessage] = useState("");

  const [pravaClonaStatus, setPravaClonaStatus] = useState(null);
  const [isActivePravaClona, setIsActivePravaClona] = useState(false);
  const [tempPravaClona, setTempPravaClona] = useState(null);
  const [pravaClonaErrorMessage, setPravaClonaErrorMessage] = useState("");

  const [iceMakerStatus, setIceMakerStatus] = useState(null);
  const [isActiveIceMaker, setIsActiveIceMaker] = useState(false);
  const [tempIceMaker, setTempIceMaker] = useState(false);
  const [iceMakerErrorMessage, setIceMakerErrorMessage] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);
  const auth = useAuth();
  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.[
    "urn:zitadel:iam:org:project:284092046917005314:roles"
  ]?.some((role) => role.employee);

  const checkTime = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    return currentHour >= 19 || currentHour < 0;
  };

  const fetchStatus = async () => {
    const statusLevaClona = fakeLevaClonaData;
    const statusPravaClona = fakePravaClonaData;
    const statusIceMaker = fakeIceMakerData;

    setLevaClonaStatus(statusLevaClona.isok);
    setPravaClonaStatus(statusPravaClona.isok);
    setIceMakerStatus(statusIceMaker.isok);

    setTempLevaClona(
      statusLevaClona.data?.device_status?.["switch:0"]?.temperature.tC
    );
    setTempPravaClona(
      statusPravaClona.data?.device_status?.["switch:0"]?.temperature.tC
    );
    setTempIceMaker(
      statusIceMaker.data?.device_status?.["switch:0"]?.temperature.tC
    );
    setIsActiveLevaClona(
      statusLevaClona.data?.device_status?.["switch:0"]?.output
    );
    setIsActivePravaClona(
      statusPravaClona.data?.device_status?.["switch:0"]?.output
    );
    setIsActiveIceMaker(
      statusIceMaker.data?.device_status?.["switch:0"]?.output
    );

    setLevaClonaErrorMessage(
      getStatusMessage(
        statusLevaClona.data?.device_status?.["switch:0"]?.output,
        statusLevaClona.data?.device_status?.["switch:0"]?.errors
      )
    );
    setPravaClonaErrorMessage(
      getStatusMessage(
        statusPravaClona.data?.device_status?.["switch:0"]?.output,
        statusPravaClona.data?.device_status?.["switch:0"]?.errors
      )
    );
    setIceMakerErrorMessage(
      getStatusMessage(
        statusIceMaker.data?.device_status?.["switch:0"]?.output,
        statusIceMaker.data?.device_status?.["switch:0"]?.errors
      )
    );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStatus();
    setIsDisabled(checkTime());

    const intervalId = setInterval(fetchStatus, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const toggleLevaClona = async () => {
    try {
      const newState = !isActiveLevaClona;
      openNotification(
        "Levá vzduchová clona",
        "Stav levé vzduchové clony byl změněn"
      );
      setIsActiveLevaClona(newState);
    } catch (error) {
      openNotification(
        "Levá vzduchová clona",
        "Nepodařilo se změnit stav clony",
        true
      );
    }
  };

  const togglePravaClona = async () => {
    try {
      const newState = !isActivePravaClona;
      openNotification(
        "Pravá vzduchová clona",
        "Stav pravé vzduchové clony byl změněn"
      );
      setIsActivePravaClona(newState);
    } catch (error) {
      openNotification(
        "Pravá vzduchová clona",
        "Nepodařilo se změnit stav clony",
        true
      );
    }
  };

  const toggleIceMaker = async () => {
    try {
      const newState = !isActiveIceMaker;
      openNotification("Výrobník ledu", "Stav výrobníku ledu byl změněn");
      setIsActiveIceMaker(newState);
    } catch (error) {
      openNotification(
        "Výrobník ledu",
        "Nepodařilo se změnit stav výrobníku",
        true
      );
    }
  };

  const openNotification = (title, description, isError = false) => {
    notification[isError ? "error" : "success"]({
      message: title,
      description: description,
      placement: "bottomRight",
      duration: 5,
    });
  };

  const LedIndicator = ({ isOn }) => {
    const indicatorStyle = isOn ? "led-indicator-on" : "led-indicator-off";
    return <div className={`led-indicator ${indicatorStyle}`}></div>;
  };

  const getStatusMessage = (isActive, errors) => {
    if (!isActive && errors && errors.length > 0) {
      return `Device is off. Errors: ${errors.join(", ")}`;
    }
    return "";
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="deviceControlContainer">
      <div className="rightContainer">
        <h3 style={{ paddingBottom: "10px" }}>Stav zařízení</h3>
        <div className="firstRow">
          <div className="titleContainer">
            <h4>Levá vzduchová clona</h4>
            <div className="pripojeni">
              <span>Připojení</span>
              <LedIndicator isOn={levaClonaStatus} />
            </div>
            <div className="stav">
              <span>Stav</span>
              <LedIndicator isOn={isActiveLevaClona} />
            </div>
            {!isEmployee && (
              <div className="temp">
                <span>Teplota</span>
                <div>
                  <span>{tempLevaClona}°C</span>
                </div>
              </div>
            )}
            {levaClonaErrorMessage && (
              <div className="error-message">{levaClonaErrorMessage}</div>
            )}
          </div>
          <div className="titleContainer">
            <h4>Pravá vzduchová clona</h4>
            <div className="pripojeni">
              <span className="spanPripojeni">Připojení</span>
              <LedIndicator isOn={pravaClonaStatus} />
            </div>
            <div className="stav">
              <span>Stav</span>
              <LedIndicator isOn={isActivePravaClona} />
            </div>
            {!isEmployee && (
              <div className="temp">
                <span>Teplota</span>
                <div>
                  <span>{tempPravaClona}°C</span>
                </div>
              </div>
            )}
            {pravaClonaErrorMessage && (
              <div className="error-message">{pravaClonaErrorMessage}</div>
            )}
          </div>
          <div className="titleContainer">
            <h4>Výrobník ledu </h4>
            <div className="pripojeni">
              <span>Připojení</span>
              <LedIndicator isOn={iceMakerStatus} />
            </div>
            <div className="stav">
              <span>Stav</span>
              <LedIndicator isOn={isActiveIceMaker} />
            </div>
            {!isEmployee && (
              <div className="temp">
                <span>Teplota</span>
                <div>
                  <span>{tempIceMaker}°C</span>
                </div>
              </div>
            )}
            {iceMakerErrorMessage && (
              <div className="error-message">{iceMakerErrorMessage}</div>
            )}
          </div>
        </div>
        <h3 style={{ paddingBottom: "10px" }}>Ovládání</h3>{" "}
        <div className="secondRow">
          <div className="titleContainer">
            <h4 style={{ marginBottom: "30px" }}>Levá vzduchová clona</h4>
            <label className="switch">
              <input
                type="checkbox"
                checked={isActiveLevaClona}
                onChange={toggleLevaClona}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="titleContainer">
            <h4 style={{ marginBottom: "30px" }}>Pravá vzduchová clona{}</h4>
            <label className="switch">
              <input
                type="checkbox"
                checked={isActivePravaClona}
                onChange={togglePravaClona}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="titleContainer">
            <h4 style={{ marginBottom: "10px" }}>Výrobník ledu</h4>
            <label className="switch">
              <input
                type="checkbox"
                checked={isActiveIceMaker}
                onChange={toggleIceMaker}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceControl;
