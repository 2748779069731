import React, { useState, useEffect } from "react";
import UploadInvoice from "./UploadInvoice";
import InvoiceTable from "./InvoiceTable";
import callApi from "../../ApiCaller";
import { Image } from "antd";

const InvoiceManagement = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchInvoices = () => {
    setLoading(true);
    callApi("invoices")
      .then((responseData) => {
        setInvoiceData(responseData);
      })
      .catch((error) => {
        console.error("Error loading invoices:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <div>
      <InvoiceTable
        data={invoiceData}
        loading={loading}
        refreshInvoices={fetchInvoices}
      />
      <UploadInvoice refreshInvoices={fetchInvoices} />
      <h2 style={{ marginTop: "20px" }}>Příklad</h2>
      <span>
        upload pdf nebo img format -{">"} ocr scan -{">"} AI -{">"} potvrzení hodnot -{">"} import do databaze
      </span>
      <div style={{ padding: "20px" }}>
        <Image width={500} src="./invoice-example.png" alt="Invoice" />
      </div>
    </div>
  );
};

export default InvoiceManagement;
