import React, { useState } from "react";
import { Spin, Upload, Button, message, Row, Col, Select, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Invoice from "./Invoice";
import Receipt from "./Receipt";
import dayjs from "dayjs";

const { Option } = Select;

const UploadInvoice = ({ refreshInvoices }) => {
  const [uploadType, setUploadType] = useState("invoice");
  const [month, setMonth] = useState(dayjs().format("MM"));
  const [year, setYear] = useState(dayjs().format("YYYY"));
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <div className="upload-container">
      <h1>Nahrát dokument </h1>

      <Row justify="left">
        <Col>
          <Button d icon={<UploadOutlined />}>
            Select File
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UploadInvoice;
