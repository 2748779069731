import React, { useState } from "react";
import ULuzickehoSeminare from "./ULuzickehoSeminare";

import { useAuth } from "oidc-react";

const ShiftSheduler = () => {
  const auth = useAuth();

  return (
    <div>
      <h1 style={{ marginBottom: "10px" }}>Směny</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ cursor: "pointer", userSelect: "none" }}>
          U lužického semináře
        </h2>
      </div>

      <ULuzickehoSeminare auth={auth} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <h2 style={{ cursor: "pointer", userSelect: "none" }}>Další Pobočka</h2>
      </div>
    </div>
  );
};

export default ShiftSheduler;
