import React, { useState, useEffect, useCallback } from "react";
import { Form, Table, Input, Button, DatePicker, Select } from "antd";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DocumentDrawerDetails from "./DocumentDrawerDetails";
import callApi from "../../ApiCaller";
import "./documents.scss";
import { getColumns } from "./DocumentColumns";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";
import dayjs from "dayjs";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const getCurrentYearDates = () => {
  const currentYear = dayjs().year();
  const startOfYear = dayjs(new Date(currentYear, 0, 1));
  const endOfYear = dayjs(new Date(currentYear, 11, 31));
  return [startOfYear, endOfYear];
};
const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [uniqueCustomers, setUniqueCustomers] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [uniqueDocumentTypes, setUniqueDocumentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [uniquePaymentTypes, setUniquePaymentTypes] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [dateRange, setDateRange] = useState(getCurrentYearDates());

  const data = filteredDocuments.map((document) => ({
    ...document,
    key: document.id,
  }));
  useEffect(() => {
    callApi("documents")
      .then((data) => {
        const aggregatedData = aggregatePaymentTypes(data);
        setDocuments(aggregatedData);
        setFilteredDocuments(aggregatedData);

        const uniqueCustomerSet = new Set(
          data.map((document) => document.Customer)
        );
        const uniqueCustomersArray = Array.from(uniqueCustomerSet);
        setUniqueCustomers(uniqueCustomersArray);

        const uniqueDocumentTypeSet = new Set(
          data.map((document) => document.DocumentType)
        );
        const uniqueDocumentTypesArray = Array.from(uniqueDocumentTypeSet);
        setUniqueDocumentTypes(uniqueDocumentTypesArray);

        const uniquePaymentTypeSet = new Set(
          data
            .map((document) => document.PaymentType)
            .filter((type) => type != null && type.trim() !== "")
        );

        const uniquePaymentTypesArray = Array.from(uniquePaymentTypeSet).sort();
        setUniquePaymentTypes(uniquePaymentTypesArray);

        const uniqueUserSet = new Set(data.map((document) => document.User));
        const uniqueUsersArray = Array.from(uniqueUserSet);
        setUniqueUsers(uniqueUsersArray);
      })
      .catch((error) => {
        console.error("Error loading documents:", error);
      });
  }, []);

  const exportToPDF = () => {
    const doc = new jsPDF();

    const tableData = filteredDocuments.map((item) => {
      return columns.map((column) => item[column.dataIndex]);
    });

    doc.autoTable({
      head: [columns.map((column) => column.title)],
      body: tableData,
    });

    doc.save("documents.pdf");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredDocuments);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(dataBlob, "documents.xlsx");
  };

  const aggregatePaymentTypes = (docs) => {
    const aggregatedDocs = new Map();

    docs.forEach((doc) => {
      // Pokud má dokument ReferenceDocumentNumber, převedeme Total na záporné číslo
      const totalAmount = doc.ReferenceDocumentNumber
        ? -Math.abs(parseFloat(doc.Total))
        : parseFloat(doc.Total);

      if (!aggregatedDocs.has(doc.id)) {
        aggregatedDocs.set(doc.id, {
          ...doc,
          Total: totalAmount, // aktualizovaná hodnota Total
          PaymentTypes: [doc.PaymentType],
        });
      } else {
        const existingDoc = aggregatedDocs.get(doc.id);
        existingDoc.PaymentTypes.push(doc.PaymentType);
        // Neměníme Total, protože již byl nastaven dříve
      }
    });

    return Array.from(aggregatedDocs.values()).map((doc) => ({
      ...doc,
      PaymentType: doc.PaymentTypes.join(", "),
    }));
  };

  const openDrawer = (document) => {
    setSelectedDocument(document);
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const applyFilters = useCallback(() => {
    let filteredData = documents;

    // Filtrování podle zákazníka, typu dokumentu, platebního typu, uživatele
    if (selectedCustomer && selectedCustomer.length > 0) {
      filteredData = filteredData.filter((document) => {
        return selectedCustomer.includes(document.Customer);
      });
    }

    if (selectedDocumentType && selectedDocumentType.length > 0) {
      filteredData = filteredData.filter((document) => {
        return selectedDocumentType.includes(document.DocumentType);
      });
    }

    if (selectedPaymentType && selectedPaymentType.length > 0) {
      filteredData = filteredData.filter((document) => {
        return selectedPaymentType.includes(document.PaymentType);
      });
    }

    if (selectedUser && selectedUser.length > 0) {
      filteredData = filteredData.filter((document) => {
        return selectedUser.includes(document.User);
      });
    }

    // Filtrování podle data
    if (dateRange && dateRange.length === 2) {
      const startDate = new Date(dateRange[0]).setHours(0, 0, 0, 0);
      const endDate = new Date(dateRange[1]).setHours(23, 59, 59, 999);
      filteredData = filteredData.filter((document) => {
        const createdDate = new Date(document.DateCreated);
        return createdDate >= startDate && createdDate <= endDate;
      });
    }

    // Přidání vyhledávacího filtru
    if (searchValue) {
      filteredData = filteredData.filter((document) =>
        Object.keys(document).some((key) =>
          document[key]
            ? document[key]
                .toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            : false
        )
      );
    }

    setFilteredDocuments(filteredData);
  }, [
    documents,
    searchValue,
    selectedCustomer,
    selectedDocumentType,
    selectedPaymentType,
    selectedUser,
    dateRange,
  ]);

  const handleResetSearch = () => {
    setSelectedCustomer(null);
    setSelectedPaymentType(null);
    setSelectedDocumentType(null);
    setSelectedUser(null);
    setFilteredDocuments(documents);
    setSearchValue("");
    setDateRange([]);
  };

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const calculateTotalRevenue = () => {
    const totalRevenue = filteredDocuments.reduce((total, document) => {
      let totalAmount = parseFloat(document.Total);

      return total + totalAmount;
    }, 0);

    return totalRevenue.toLocaleString("cs-CZ", {
      style: "currency",
      currency: "CZK",
    });
  };

  const columns = getColumns(openDrawer);

  return (
    <>
      <h1>Dokumenty</h1>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{
          marginBottom: 10,
        }}
      >
        <Form.Item
          style={{
            width: "250px",
            marginRight: "45px",
          }}
        >
          <Form.Item
            style={{
              width: "250px",
              marginBottom: "5px",
            }}
          >
            <Search
              placeholder="Hledat"
              onSearch={applyFilters}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </Form.Item>
          <Form.Item
            style={{
              width: "250px",
              marginBottom: "5px",
            }}
          >
            <Select
              mode="multiple"
              placeholder="Vyberte zákazníka"
              value={selectedCustomer || []}
              onChange={(value) => {
                setSelectedCustomer(value);
                applyFilters();
              }}
            >
              {uniqueCustomers.map((customer) => (
                <Option key={customer} value={customer}>
                  {customer}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Form.Item
              style={{
                width: "250px",
              }}
            >
              <Select
                mode="multiple"
                placeholder="Vyberte obsluhu"
                value={selectedUser || []}
                onChange={(value) => {
                  setSelectedUser(value);
                  applyFilters();
                }}
              >
                {uniqueUsers.map((user) => (
                  <Option key={user} value={user}>
                    {user}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item
            style={{
              width: "250px",
              marginBottom: "5px",
            }}
          >
            <RangePicker
              locale={locale}
              placeholder={["Od", "Do"]}
              onChange={(dates) => {
                setDateRange(dates);
                applyFilters();
              }}
              value={dateRange}
            />
          </Form.Item>
          <Form.Item
            style={{
              width: "250px",
              marginBottom: "5px",
            }}
          >
            <Select
              mode="multiple"
              placeholder="Vyberte platební typ"
              value={selectedPaymentType || []}
              onChange={(value) => {
                setSelectedPaymentType(value);
                applyFilters();
              }}
            >
              {uniquePaymentTypes.map((paymentType) => (
                <Option key={paymentType} value={paymentType}>
                  {paymentType}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{
              width: "250px",
            }}
          >
            <Select
              mode="multiple"
              placeholder="Vyberte typ dokumentu"
              value={selectedDocumentType || []}
              onChange={(value) => {
                setSelectedDocumentType(value);
                applyFilters();
              }}
            >
              {uniqueDocumentTypes.map((documentType) => (
                <Option key={documentType} value={documentType}>
                  {documentType}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item>
            <Button
              style={{ marginBottom: "5px", marginRight: "10px" }}
              onClick={exportToPDF}
            >
              Export to PDF
            </Button>
            <Button onClick={exportToExcel}>Export to Excel</Button>
          </Form.Item>
          <Button onClick={handleResetSearch}>Reset</Button>
        </Form.Item>
      </Form>
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        footer={() => (
          <div>
            <span>Celková tržba: {calculateTotalRevenue()}</span>
            <br />
            <span>Počet zobrazených dokumentů: {filteredDocuments.length}</span>
          </div>
        )}
      />
      {selectedDocument && (
        <DocumentDrawerDetails
          isDrawerVisible={isDrawerVisible}
          closeDrawer={closeDrawer}
          selectedDocument={selectedDocument}
        />
      )}
    </>
  );
};

export default Documents;
